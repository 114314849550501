import { useContext, useState, useEffect, createContext } from "react";
import { supabase } from "../../supabase";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      setIsLoggedIn(session?.user ? true : false);
      setIsLoading(false);

      const { data: listener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          setUser(session?.user ?? null);
          setIsLoggedIn(session?.user ? true : false);
          setIsLoading(false);
        }
      );
      return () => {
        listener?.unsubscribe();
      };
    };
    checkSession();
  }, []);

  async function signUpWithEmail(credentials) {
    const { data, error } = await supabase.auth.signUp({
      email: credentials.email,
      password: credentials.password,
    });

    if (data.user) {
      setUser(data.user);
      setIsLoggedIn(true);
    }

    if (error) {
      alert(error);
    }
  }

  async function signIn(credentials) {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: credentials.email,
      password: credentials.password,
    });

    if (data.user) {
      setUser(data.user);
      setIsLoggedIn(true);
    }

    if (error) {
      alert(error);
    }
  }

  async function signInWithGoogle() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });

    if (data.user) {
      setUser(data.user);
      setIsLoggedIn(true);
    }

    if (error) {
      alert(error);
    }
  }

  async function signOut() {
    const { error } = await supabase.auth.signOut();

    if (error) {
      alert(error);
    } else {
      setIsLoggedIn(false);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signUpWithEmail,
        signIn,
        signInWithGoogle,
        isLoading,
        setIsLoggedIn,
        isLoggedIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
