import {
  ArrowPathIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon,
  HeartIcon,
  ArrowUpTrayIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CreatePodResponse from "../components/single-pod/CreatePodResponse";
import PodResponseCard from "../components/single-pod/PodResponseCard";
import { PostContext } from "../utils/context/ApiContext";
import { AudioContext } from "../utils/context/AudioContext";

const SinglePod = () => {
  const navigate = useNavigate();
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2022/11/newhoustonwehaveapodcast.png";
  const verifyUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/verified.png";
  const { currentAudioUrl, setCurrentAudioUrl } = useContext(AudioContext);
  const { currentPod, setCurrentPod } = useContext(PostContext);
  const {
    audio_url,
    content,
    excerpt,
    img_url,
    pod_title,
    featuredImage,
    postsFields,
    author,
  } = currentPod;

  useEffect(() => {
    setCurrentAudioUrl(audio_url);
    console.log(currentPod);
  }, []);
  return (
    <div style={podStyle}>
      <ChevronLeftIcon
        style={backIconStyle}
        color={"#FFF"}
        onClick={() => navigate(-1)}
      />
      <div style={containerStyle}>
        <div style={headingContainerStyle}>
          <div style={profileImageContainerStyle}>
            <img
              src={
                featuredImage?.node.sourceUrl
                  ? featuredImage?.node.sourceUrl
                  : imgUrl
              }
              style={profileImageStyle}
              alt="Profile"
            />
          </div>

          <div style={contentStyle}>
            <div style={displayNameContainerStyle}>
              <div style={{ display: "flex" }}>
                <p style={fullNameStyle}>{author?.node.name}</p>
                <img
                  src={verifyUrl}
                  style={verifiedStyle}
                  alt="Verified badge"
                />
              </div>
              <p style={displayNameStyle}>@{author?.node.name}</p>
            </div>
          </div>
        </div>

        <div
          style={summaryStyle}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />

        <img
          src={
            featuredImage?.node.sourceUrl
              ? featuredImage?.node.sourceUrl
              : imgUrl
          }
          style={featuredImageStyle}
          alt="Featured"
        />

        <p style={podDetailsStyle}>
          7:33 PM · Jan 10, 2023 ·{" "}
          <span style={spanStyle}>
            {postsFields?.podInteractions > 0
              ? postsFields?.podInteractions
              : 0}
          </span>{" "}
          Views
        </p>

        <div style={statsContainerStyle}>
          <div style={actionContainerStyle}>
            <p style={actionTextStyle}>
              <span style={spanStyle}>
                {postsFields?.podRepods > 0 ? postsFields?.podRepods : 0}
              </span>{" "}
              Repods
            </p>
          </div>

          <div style={actionContainerStyle}>
            <p style={actionTextStyle}>
              <span style={spanStyle}>
                {postsFields?.podResponses > 0 ? postsFields?.podResponses : 0}
              </span>{" "}
              Responses
            </p>
          </div>

          <div style={actionContainerStyle}>
            <p style={actionTextStyle}>
              <span style={spanStyle}>
                {postsFields?.podLikes > 0 ? postsFields?.podLikes : 0}
              </span>{" "}
              Likes
            </p>
          </div>
        </div>

        <div style={buttonContainerStyle}>
          <div style={actionContainerStyle}>
            <ChatBubbleLeftIcon color={"#777"} style={iconStyle} />
          </div>

          <div style={actionContainerStyle}>
            <ArrowPathIcon color={"#777"} style={iconStyle} />
          </div>

          <div style={actionContainerStyle}>
            <HeartIcon color={"#777"} style={iconStyle} />
          </div>

          <div style={actionContainerStyle}>
            <ArrowUpTrayIcon color={"#777"} style={iconStyle} />
          </div>
        </div>
      </div>

      <CreatePodResponse imgUrl={img_url} />

      <div style={dividerStyle} />

      <PodResponseCard />
      <PodResponseCard />
      <PodResponseCard />
      <PodResponseCard />
      <PodResponseCard />
    </div>
  );
};

export default SinglePod;

const podStyle = {
  paddingTop: "20px",
  borderBottom: "1px solid #2f2f2f",
  paddingBottom: "30px",
  marginTop: "0px",
  width: "600px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  borderRight: "1px solid #2f2f2f",
  height: "auto",
  gap: "10px",
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "0px",
  width: "546px",
  maxWidth: "546px",
};

const podDetailsStyle = {
  color: "#777",
  borderBottom: "1px solid #444",
  paddingTop: "5px",
  paddingBottom: "10px",
};

const backIconStyle = {
  height: "28px",
  width: "28px",
  alignSelf: "flex-start",
  justifySelf: "flex-start",
  marginTop: "-10px",
  marginLeft: "15px",
  marginBottom: "10px",
  cursor: "pointer",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "100%",
  maxWidth: "546px",
};

const iconStyle = {
  height: "25px",
  width: "25px",
};

const actionTextStyle = {
  color: "#777",
};

const headingContainerStyle = {
  display: "flex",
  flexDirection: "row",
};

const actionContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingTop: "10px",
};

const profileImageContainerStyle = {
  marginRight: "10px",
  marginBottom: "5px",
};

const verifiedStyle = {
  width: "15px",
  objectFit: "contain",
};

const profileImageStyle = {
  width: "48px",
  height: "48px",
  borderRadius: "500px",
};

const displayNameContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "0px",
};

const displayNameStyle = {
  color: "#999",
  fontSize: "15px",
};

const fullNameStyle = {
  fontWeight: "600",
  fontSize: "15px",
  color: "#FFF",
  marginRight: "5px",
};

const summaryStyle = {
  fontSize: "13px",
  color: "#FFF",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
};

const featuredImageStyle = {
  width: "auto",
  height: "485px",
  borderRadius: "10px",
  objectFit: "cover",
  marginTop: "20px",
  marginBottom: "10px",
  border: "1px solid #444",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-around",
  borderBottom: "1px solid #444",
  paddingBottom: "30px",
};

const statsContainerStyle = {
  display: "flex",
  justifyContent: "start",
  gap: "25px",
  marginBottom: "20px",
  paddingBottom: "20px",
  borderBottom: "1px solid #444",
  paddingTop: "5px",
};

const dividerStyle = {
  width: "100%",
  height: "1px",
  background: "#444444",
  display: "flex",
  alignSelf: "center",
};

const smallerDividerStyle = {
  width: "96%",
  height: "1px",
  background: "#444444",
  display: "flex",
  alignSelf: "center",
};

const spanStyle = {
  fontWeight: "600",
  color: "#FFF",
};
