import React, { useContext } from "react";
import { PostContext } from "../../utils/context/ApiContext";

const ResultsHeader = () => {
  const { searchedPosts, searchTerm } = useContext(PostContext);
  return (
    <div style={resultContainerStyle}>
      <p style={resultHeadingStyle}>{searchTerm}</p>
      <p style={resultHeadingStyle}>Results: {searchedPosts.length}</p>
    </div>
  );
};

export default ResultsHeader;

const resultContainerStyle = {
  padding: "10px",
  display: "flex",
  justifyContent: "space-between",
  background: "#222",
};

const resultHeadingStyle = {
  color: "#FFF",
  fontWeight: "500",
};
