import WhatsHappening from "./WhatsHappening";
import WhoToFollow from "./WhoToFollow";

const RightSidebar = () => {
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <WhatsHappening />
        <div style={spacerStyle} />
        <WhoToFollow />
      </div>
    </div>
  );
};

export default RightSidebar;

const containerStyle = {
  width: "25%",
  padding: "10px 0px 10px 10px",
};

const contentStyle = {
  width: "100%",
  gap: "10px",
};

const spacerStyle = {
  margin: "10px 0px",
};
