import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import UserCard from '../user-component/UserCard';

const WhoToFollow = () => {
  const { allUsers } = useContext(UserContext);

  return (
    <div style={containerStyle}>
      <p style={headingStyle}>Who to follow</p>

      {allUsers.map((user) => (
        <UserCard key={user.id} user={user} />
      ))}
    </div>
  );
};

export default WhoToFollow;

const containerStyle = {
  background: '#222',
  borderRadius: '10px',
  padding: '10px',
};

const headingStyle = {
  fontWeight: '600',
  color: '#FFF',
};
