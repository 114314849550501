import React from "react";
import {
  PhotoIcon,
  GifIcon,
  FaceSmileIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import SingleMessageCard from "./SingleMessageCard";

const MessageView = () => {
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/2M7cCBz_400x400.jpg";

  return (
    <div style={containerStyle}>
      <div style={headerContainerStyle}>
        <img style={profileImageStyle} src={imgUrl} alt="Profile" />
        <p style={displayNameStyle}>702 Pros</p>
        <p style={handleNameStyle}>@702pros</p>

        <p style={bioStyle}>
          Veteran-Led Marketing-First Website Design and Branding Company Based
          in Las Vegas, NV
        </p>
        <div style={detailsContainerStyle}>
          <p>Joined January 2023</p>
          <p>·</p>
          <p>162.3K Followers</p>
        </div>
      </div>

      <div style={messagesViewStyle}>
        <SingleMessageCard isSender={true} />
        <SingleMessageCard isSender={false} />
        <SingleMessageCard isSender={true} />
        <SingleMessageCard isSender={false} />
        <SingleMessageCard isSender={true} />
      </div>

      <div style={inputContainerStyle}>
        <PhotoIcon style={iconStyle} />
        <GifIcon style={iconStyle} />
        <FaceSmileIcon style={iconStyle} />

        <input style={inputStyle} placeholder="Start a new message" />

        <PaperAirplaneIcon style={iconStyle} />
      </div>
    </div>
  );
};

export default MessageView;

const containerStyle = {
  color: "#FFF",
  padding: "10px",
  // width: "100%",
  height: "100vh",
};

const displayNameStyle = {
  fontWeight: "600",
  color: "#FFF",
  fontSize: "18px",
};

const handleNameStyle = {
  fontWeight: "400",
  color: "#777",
  fontSize: "16px",
  marginBottom: "10px",
};

const bioStyle = {
  marginBottom: "10px",
  fontSize: "14px",
  textAlign: "center",
};

const detailsContainerStyle = {
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "14px",
  color: "#777",
};

const headerContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "25px",
  borderBottom: "1px solid #2f2f2f",
};

const profileImageStyle = {
  height: "60px",
  width: "60px",
  borderRadius: "500px",
};

const inputStyle = {
  width: "100%",
  flex: "1",
  paddingLeft: "20px",
  paddingRight: "10px",
  background: "#222",
  border: "none",
  fontSize: "18px",
  color: "#FFF",
};

const iconStyle = {
  height: "20px",
  width: "20px",
};

const inputContainerStyle = {
  width: "100%",
  background: "#222",
  borderRadius: "25px",
  display: "flex",
  alignItems: "center",
  padding: "15px",
  gap: "5px",
};

const messagesViewStyle = {
  marginBottom: "10px",
  overflowY: "scroll",
  height: "550px",
  padding: "10px",
  width: "100%",
};
