import React, { useEffect, useContext } from "react";
import { Route, Routes, Switch } from "react-router-dom";
import "./App.css";
import CategoriesHeader from "./components/categories-list/CategoriesHeader";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./screens/Home";
import Upload from "./screens/Upload";
import { ScreenContext } from "./utils/context/ScreenContext";

function App() {
  const { isMobile } = useContext(ScreenContext);

  const containerStyle = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    background: "#000",
    // overflowX: "hidden",
    overflowY: "scroll",
  };

  const pageStyle = {
    width: "100%",
    maxWidth: isMobile ? "100%" : "1200px",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const dividerStyle = {
    width: "100vw",
    height: "1px",
    background: "#444444",
    display: "flex",
    alignSelf: "center",
  };

  return (
    <div style={containerStyle}>
      <div style={pageStyle}>
        <Header />
        <div style={dividerStyle}></div>
        <CategoriesHeader />
        <div style={dividerStyle}></div>

        <Routes>
          <Route exact path="/explore/:term" element={<Home />} />
          <Route exact path="/profile/:ID" element={<Home />} />
          <Route exact path="/profile" element={<Home />} />
          <Route exact path="/messages" element={<Home />} />
          <Route exact path="/notifications" element={<Home />} />
          <Route exact path="/explore" element={<Home />} />
          <Route exact path="/single/:ID" element={<Home />} />
          <Route exact path="/upload" element={<Upload />} />
          <Route exact path="/" element={<Home />} />
        </Routes>

        <Footer />
      </div>
    </div>
  );
}

export default App;
