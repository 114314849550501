import React from "react";
import "./Links.css";

const LinksColumnComponent = ({ title, links }) => {
  const displayLinks = links.map((each) => {
    return (
      <a key={each.name} href={each.href} style={linkStyle}>
        {each.name}
      </a>
    );
  });

  return (
    <div style={containerStyle}>
      <p style={headingStyle}>{title}</p>
      {displayLinks}
    </div>
  );
};

export default LinksColumnComponent;

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
};

const headingStyle = {
  fontWeight: "600",
  color: "#FFF",
  marginBottom: "10px",
};

const linkStyle = {
  color: "#FFF",
  fontSize: "14px",
};
