import React, { useContext } from "react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import "./ExploreCard.css";
import { PostContext } from "../../utils/context/ApiContext";
import { useNavigate } from "react-router-dom";

const ExploreCard = ({
  title = "NPR",
  genre = "Politics",
  status = "Trending",
}) => {
  const navigate = useNavigate();
  const { setSearchTerm, searchPosts } = useContext(PostContext);
  const handleCategoryClick = (term) => {
    searchPosts(term);
    setSearchTerm(term);
    navigate("/");
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle} onClick={() => handleCategoryClick(title)}>
        <p style={subHeadingStyle}>
          {genre} · {status}
        </p>
        <p style={mainHeadingStyle}>{title}</p>
        <p style={subHeadingStyle}>11.6k Tweets</p>
        <button style={moreButtonStyle}>
          <EllipsisHorizontalIcon
            className="ellipsis_more_icon"
            color={"#999"}
            style={ellipsisStyle}
          />
        </button>
      </div>
    </div>
  );
};

export default ExploreCard;

const containerStyle = {
  background: "transparent",
  width: "100%",
  margin: "20px 0",
};

const contentStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  position: "relative",
  gap: "3px",
  cursor: "pointer",
};

const subHeadingStyle = {
  color: "#777",
  fontWeight: "400",
  fontSize: "13px",
};

const mainHeadingStyle = {
  color: "#FFF",
  fontWeight: "600",
  fontSize: "16px",
};

const moreButtonStyle = {
  position: "absolute",
  top: "5px",
  right: "0px",
  border: "none",
  background: "transparent",
  cursor: "pointer",
};

const ellipsisStyle = {
  height: "20px",
  width: "20px",
};
