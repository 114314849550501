import React from "react";

const Button = ({
  text,
  color,
  background,
  padding,
  children,
  handleClick,
}) => {
  const buttonStyle = {
    background: background,
    padding: padding ? padding : "12px 40px 12px 40px",
    borderRadius: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const textStyle = {
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: "13px",
    color: color,
  };

  return (
    <div style={buttonStyle} onClick={handleClick}>
      {children}
      <p style={textStyle}>{text}</p>
    </div>
  );
};

export default Button;
