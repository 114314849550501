export const siteLinks = [
  {
    title: "702 Pros",
    href: "https://702pros.com/",
    text: "Grow your brand with digital by",
  },
  {
    title: "HoneyHat",
    href: "https://www.honeyhat.com/",
    text: "Connect & manage B2B businesses with",
  },
  {
    title: "Pulsenest",
    href: "https://pulsenest.com/",
    text: "Manage customers & projects with",
  },
  {
    title: "OnSago",
    href: "https://onsago.com/",
    text: "Create online promotions in minutes with",
  },
  {
    title: "Sparkmeta",
    href: "https://sparkmeta.com/",
    text: "Invest for equity in pre-market startups with",
  },
  {
    title: "Mattersly",
    href: "https://mattersly.com/",
    text: "Show what Matters to You with",
  },
  {
    title: "ThingsTDN",
    href: "https://www.thingstdn.com/",
    text: "Find things to do in your city",
  },
  {
    title: "Pool Launch",
    href: "https://poollaunch.com/",
    text: "Hire pro pool contractors with",
  },
  {
    title: "Linkpeas",
    href: "https://linkpeas.com/",
    text: "Create & manage your brand links with",
  },
  {
    title: "Provingo",
    href: "https://provingo.com/",
    text: "Locate & schedule local service providers with",
  },
  {
    title: "Workergram",
    href: "https://workergram.com/",
    text: "Book workers for gigs in seconds with",
  },
  {
    title: "Scoutshift",
    href: "https://scoutshift.com/",
    text: "Show your skills & build your portfolio with",
  },
  {
    title: "Truthrow",
    href: "https://truthrow.com/",
    text: "The truth between news and politics",
  },
  {
    title: "UpNitro",
    href: "https://www.upnitro.com/",
    text: "Local New Stories from your community",
  },
];

export const exploreLinks = [
  {
    name: "Write a review",
    href: "#",
  },
  {
    name: "Add a Pod",
    href: "#",
  },
  {
    name: "Join",
    href: "#",
  },
  {
    name: "Blog",
    href: "#",
  },
  {
    name: "Help Center",
    href: "#",
  },
  {
    name: "TapPlus",
    href: "#",
  },
  {
    name: "Inspiration",
    href: "#",
  },
  {
    name: "Content",
    href: "#",
  },
];
