import {
  Autorenew,
  Block,
  BorderClear,
  BorderColor,
  InfoOutlined,
  Widgets,
} from '@material-ui/icons';
import { findByLabelText } from '@testing-library/react';
import React, { useState } from 'react';
// import "../css/styles.css";

const uploadPost = (event) => {
  let audioInput = document.getElementById('audio_upload');
  let imgInput = document.getElementById('img_upload');
  let content = document.getElementById('content').value;

  let audio_file = audioInput.files[0];
  let img_file = imgInput.files[0];

  console.log(audio_file);
  console.log(img_file);

  //Create Form Data
  var data = new FormData();
  data.append('author_id', 1);
  data.append('audio_file', audio_file);
  data.append('img_file', img_file);
  data.append('pod_content', content);
  data.append('location', '');

  event.preventDefault();

  fetch('https://www.tappods.com/wp-json/tpapi/v1/upload', {
    method: 'POST',
    body: data,
  })
    .then((res) => res.json())
    .then(function (data) {
      console.log(data);
    })
    .catch((error) => console.log('error'));
};

const addFile = (event) => {
  event.preventDefault();

  //Upload file to specified target using for attribute
  var input = event.target.getAttribute('for');
  document.getElementById(input).click();
};

const record = (event) => {
  event.preventDefault();
};

const TestUpload = () => {
  const recordIconUrl =
    'https://www.tappods.com/wp-content/uploads/2023/01/record-icon.svg';
  const uploadIconUrl =
    'https://www.tappods.com/wp-content/uploads/2023/01/upload-icon.svg';
  const imageIconUrl =
    'https://www.tappods.com/wp-content/uploads/2023/01/gallery.png';
  const closedCaptionIconUrl =
    'https://www.tappods.com/wp-content/uploads/2023/01/subtitles.png';
  const locationIconUrl =
    'https://www.tappods.com/wp-content/uploads/2023/01/placeholder.png';

  window.onload = function () {
    var audio = document.getElementById('audio_upload');
    audio.addEventListener('change', handleAudioFile);

    var media = document.getElementById('img_upload');
    media.addEventListener('change', handleMediaUpload);
  };

  function handleAudioFile(event) {
    //Initalize File Data
    var fileInput = event.target;
    var filePath = event.target.value;
    var file = event.target.files[0];
    var objUrl = URL.createObjectURL(file);

    //Set Audio file in Player
    document.getElementById('audio').src = objUrl;
    document.getElementById('audio').load();
    document.getElementById('audio').play();
    document.getElementById('audio').style.display = 'block';
  }

  function handleMediaUpload(event) {
    var files = event.target.files;
    var img_preview = document.getElementById('img_preview');

    img_preview.src = URL.createObjectURL(files[0]);
    img_preview.style.display = 'block';
  }

  return (
    <div style={containerStyle}>
      <form>
        <div style={btnWrapperStyle}>
          <button style={buttonStyle} onClick={record}>
            <img src={recordIconUrl} style={imgIconStyle} /> Record
          </button>

          <button style={buttonStyle} onClick={addFile} for='audio_upload'>
            <img src={uploadIconUrl} style={imgIconStyle} />
            Upload
          </button>
        </div>

        <input
          id='audio_upload'
          type='file'
          name='audio_file'
          style={inputStyle}
        />
        <input id='img_upload' type='file' name='img' style={inputStyle} />

        <textarea
          id='content'
          style={txtAreaStyle}
          class='caption_txt'
          name='caption'
          placeholder="What's new?"
          rows='6'></textarea>

        <img id='img_preview' src='' style={imgPrevStyle} />

        <audio id='audio' src='' controls autoplay style={audioStyle}></audio>

        <hr class='solid' />

        <div style={actionsContainerStyle}>
          <img
            style={actionIconStyle}
            src={imageIconUrl}
            alt='Icon'
            for='img_upload'
            onClick={addFile}
          />

          <img
            style={actionIconStyle}
            src={closedCaptionIconUrl}
            alt='Closed caption icon'
          />

          <img
            style={actionIconStyle}
            src={locationIconUrl}
            alt='Location icon'
          />

          <input
            style={submitBtn}
            onSubmit={uploadPost}
            type='submit'
            value='Post'
          />
        </div>
      </form>
    </div>
  );
};

export default TestUpload;

const containerStyle = {
  display: 'block',
  height: 'auto',
  width: '600px',
  backgroundColor: '#000',
  margin: 'auto',
  padding: '50px',
  borderRadius: '10px',
};

const btnWrapperStyle = {
  display: 'flex',
  gap: '25px',
};
const imgIconStyle = {
  filter: 'invert(1)',
};
const buttonStyle = {
  width: '125px',
  padding: '8px 10px',
  border: '1px solid #FFF',
  borderRadius: '500px',
  background: '#000',
  color: '#FFF',
  fontSize: '17px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  cursor: 'pointer',
};
const inputStyle = {
  display: 'none',
};

const txtAreaStyle = {
  display: 'block',
  marginTop: 15,
  width: '100%',
  padding: 10,
  border: '0px',
  color: '#fff',
  background: 'transparent',
};

const imgPrevStyle = {
  display: 'none',
  height: 500,
  width: '100%',
  borderRadius: 10,
  objectFit: 'cover',
};
const audioStyle = {
  display: 'none',
  width: '100%',
};

const actionIconStyle = {
  height: '26px',
  width: '26px',
  filter: 'invert(1)',
};
const actionsContainerStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '15px',
  paddingTop: 15,
};
const submitBtn = {
  padding: '6px',
  width: 90,
  borderRadius: 500,
  color: 'rgb(255, 255, 255)',
  background: 'rgb(255, 0, 120)',
  border: 'none',
  fontSize: 17,
  fontWeight: 600,
};
