import React, { useState } from "react";
import MessageCard from "./MessageCard";
import MessageView from "./MessageView";
import { Cog6ToothIcon, UserPlusIcon } from "@heroicons/react/24/outline";

const Messages = () => {
  const [messageSearch, setMessageSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleSearchInput = (e) => {
    setMessageSearch(() => e.target.value);
  };

  const inputStyle = {
    background: "#000",
    borderRadius: "500px",
    border: "1px solid #2f2f2f",
    padding: "10px 5px",
    width: "100%",
    fontSize: "14px",
    color: "#FFF",
    textAlign: isFocused ? "left" : "center",
    paddingLeft: "20px",
    marginTop: "5px",
    marginBottom: "10px",
  };

  return (
    <div style={containerStyle}>
      <div>
        <div style={headingContainerStyle}>
          <h1 style={headerStyle}>Messages</h1>
          <Cog6ToothIcon style={iconStyle} />
          <UserPlusIcon style={iconStyle} />
        </div>

        <div style={inputContainerStyle}>
          <input
            style={inputStyle}
            placeholder="Search Direct Messages"
            value={messageSearch}
            onChange={handleSearchInput}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>

        <div>
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
        </div>
      </div>
    </div>
  );
};

export default Messages;

const containerStyle = {
  color: "#FFF",
  display: "flex",
  flexDirection: "column",
};

const headerStyle = {
  fontSize: "18px",
  flex: "1",
};

const inputContainerStyle = {
  padding: "10px 20px",
};

const iconStyle = {
  height: "20px",
  width: "20px",
};

const headingContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  width: "100%",
};
