import {
  ArrowPathIcon,
  ArrowUpTrayIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PostContext } from "../../utils/context/ApiContext";
import { AudioContext } from "../../utils/context/AudioContext";
import { ScreenContext } from "../../utils/context/ScreenContext";
import { UserContext } from "../../utils/context/UserContext";
import useOnScreen from "../../utils/hooks/useOnScreen";

const PodCard = ({ pod }) => {
  const navigate = useNavigate();
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const {
    ID,
    audio_url,
    content,
    excerpt = "",
    img_url,
    title,
    pod_likes,
    featuredImage,
    postsFields,
    author,
  } = pod;
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2022/11/newhoustonwehaveapodcast.png";
  const verifyUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/verified.png";

  const { currentAudioUrl, setCurrentAudioUrl } = useContext(AudioContext);
  const { currentPod, setCurrentPod, likePod } = useContext(PostContext);
  const { isMobile } = useContext(ScreenContext);
  const { user, setShowLogin } = useContext(UserContext);

  useEffect(() => {
    if (isVisible) {
      if (postsFields?.audioFile) {
        setCurrentAudioUrl(postsFields?.audioFile);
      } else {
        console.log(pod);
      }
    }
  }, [isVisible]);

  const handlePodClick = (e) => {
    if (e.target.nodeName.toLowerCase() !== "svg") {
      setCurrentPod(pod);
      navigate(`/single/${pod.databaseId}`);
    }
  };

  const handlePodLikeClick = () => {
    if (Object.keys(user).length > 0) {
      likePod(ID, user.id);
    } else {
      setShowLogin(true);
    }
  };

  const podStyle = {
    paddingTop: "20px",
    width: isMobile ? "100%" : "546px",
    maxWidth: isMobile ? "100%" : "546px",
    borderBottom: "1px solid #2f2f2f",
    paddingBottom: "30px",
    scrollSnapAlign: "start",
    cursor: "pointer",
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    maxWidth: isMobile ? "100%" : "546px",
  };

  const featuredImageStyle = {
    width: isMobile ? "100%" : "485px",
    height: isMobile ? "100%" : "485px",
    borderRadius: "10px",
    objectFit: "cover",
  };

  return (
    <div ref={ref} style={podStyle} onClick={handlePodClick}>
      <div style={containerStyle}>
        <div style={profileImageContainerStyle}>
          <img
            src={
              featuredImage?.node?.sourceUrl
                ? featuredImage?.node?.sourceUrl
                : imgUrl
            }
            style={profileImageStyle}
            alt="Profile"
          />
        </div>

        <div style={contentStyle}>
          <div style={displayNameContainerStyle}>
            <p style={fullNameStyle}>{author?.node?.name}</p>
            <img src={verifyUrl} style={verifiedStyle} alt="Verified badge" />
            <p style={displayNameStyle}>@{author?.node?.name}</p>
          </div>

          <div
            style={summaryStyle}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />

          <img
            src={
              featuredImage?.node?.sourceUrl
                ? featuredImage?.node?.sourceUrl
                : imgUrl
            }
            style={featuredImageStyle}
            alt="Featured"
          />

          <div style={buttonContainerStyle}>
            <div style={actionContainerStyle}>
              <ChartBarIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>
                {postsFields?.podInteractions > 0
                  ? postsFields?.podInteractions
                  : 0}
              </p>
            </div>

            <div style={actionContainerStyle}>
              <ArrowPathIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>
                {postsFields?.podRepods > 0 ? postsFields?.podRepods : 0}
              </p>
            </div>

            <div style={actionContainerStyle}>
              <ChatBubbleLeftIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>
                {postsFields?.podResponses > 0 ? postsFields?.podResponses : 0}
              </p>
            </div>

            <div style={actionContainerStyle}>
              <HeartIcon
                color={"#777"}
                style={iconStyle}
                onClick={handlePodLikeClick}
              />
              <p style={actionTextStyle}>
                {postsFields?.podLikes > 0 ? postsFields?.podLikes : 0}
              </p>
            </div>

            <div style={actionContainerStyle}>
              <ArrowUpTrayIcon color={"#777"} style={iconStyle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodCard;

const containerStyle = {
  display: "flex",
  flexDirection: "row",
};

const iconStyle = {
  height: "18px",
  width: "18px",
};

const actionTextStyle = {
  color: "#777",
  fontSize: "14px",
};

const actionContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  gap: "10px",
  marginTop: "5px",
};

const profileImageContainerStyle = {
  marginRight: "10px",
};

const verifiedStyle = {
  width: "17px",
};

const profileImageStyle = {
  width: "48px",
  height: "48px",
  borderRadius: "500px",
};

const displayNameContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "5px",
};

const displayNameStyle = {
  color: "#999",
  fontSize: "15px",
};

const fullNameStyle = {
  fontWeight: "600",
  fontSize: "15px",
  color: "#FFF",
};

const summaryStyle = {
  fontSize: "13px",
  color: "#FFF",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
  height: "125px",
  overflow: "hidden",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "start",
  gap: "40px",
};

const audioStyle = {
  width: "100%",
};
