import React from "react";
import CategoriesList from "./CategoriesList";

const CategoriesHeader = () => {
  return (
    <div style={containerStyle}>
      <CategoriesList />
    </div>
  );
};

export default CategoriesHeader;

const containerStyle = {
  height: "50px",
  width: "100%",
};
