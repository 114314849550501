import React, { useContext, useState, createContext } from "react";

export const ScrollContext = createContext();

export const ScrollContextProvider = ({ children }) => {
  const [isAuto, setIsAuto] = useState(true);
  const [moveUp, setMoveUp] = useState(false);
  const [moveDown, setMoveDown] = useState(false);

  return (
    <ScrollContext.Provider
      value={{ isAuto, setIsAuto, moveUp, moveDown, setMoveUp, setMoveDown }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
