import { createContext, useState, useEffect, createRef } from "react";

export const AudioContext = createContext();

export const AudioContextProvider = ({ children }) => {
  const [currentAudioUrl, setCurrentAudioUrl] = useState("");

  return (
    <AudioContext.Provider
      value={{
        currentAudioUrl,
        setCurrentAudioUrl,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
