import React, { useState } from "react";
import AllNotifications from "./AllNotifications";
import MentionsNotifications from "./MentionsNotifications";
import MessagesNotifications from "./MessagesNotifications";

const Notifications = () => {
  const [focused, setFocused] = useState("all");
  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Notifications</h1>

      <div style={selectTabsStyle}>
        <button
          onClick={() => setFocused("all")}
          style={focused == "all" ? focusedTabStyle : tabsStyle}
        >
          All
        </button>
        <button
          onClick={() => setFocused("mentions")}
          style={focused == "mentions" ? focusedTabStyle : tabsStyle}
        >
          Mentions
        </button>
        <button
          onClick={() => setFocused("messages")}
          style={focused == "messages" ? focusedTabStyle : tabsStyle}
        >
          Messages
        </button>
      </div>

      <div>
        {focused == "all" && <AllNotifications />}
        {focused == "mentions" && <MentionsNotifications />}
        {focused == "messages" && <MessagesNotifications />}
      </div>
    </div>
  );
};

export default Notifications;

const containerStyle = {};

const headingStyle = {
  color: "#FFF",
  fontSize: "18px",
  padding: "10px",
};

const selectTabsStyle = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "10px",
  marginTop: "10px",
  paddingBottom: "0",
  borderBottom: "1px solid #2f2f2f",
};

const tabsStyle = {
  background: "#000",
  color: "#777",
  border: "none",
  fontSize: "16px",
  fontWeight: "500",
  borderBottom: "2px solid #000",
  padding: "0px 10px 20px 10px",
  cursor: "pointer",
};

const focusedTabStyle = {
  ...tabsStyle,
  color: "#FFF",
  fontWeight: "600",
  borderBottom: "2px solid #FF0078",
};
