import React, { useState } from "react";

const SingleMessageCard = ({ isSender }) => {
  const containerStyle = {
    padding: "10px",
    color: "#FFF",
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: isSender ? "start" : "end",
  };

  const messageBubbleStyle = {
    padding: "10px",
    background: "#444",
    color: "#FFF",
    borderRadius: isSender ? "15px 15px 15px 1px" : "15px 15px 1px 15px",
    width: "300px",
  };

  return (
    <div style={containerStyle}>
      <div style={messageBubbleStyle}>
        <p>Hey there, how you doin?</p>
      </div>
      <p style={detailsStyle}>Nov 10, 2015, 2:50 PM</p>
    </div>
  );
};

export default SingleMessageCard;

const detailsStyle = {
  color: "#777",
};
