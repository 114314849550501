import {
  ArrowPathIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import React, { useContext, useEffect } from "react";

import CreatePodResponse from "./CreatePodResponse";
import { PostContext } from "../../utils/context/ApiContext";
import { AudioContext } from "../../utils/context/AudioContext";
import { Audio } from "react-loader-spinner";

const PodResponseCard = () => {
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2022/11/newhoustonwehaveapodcast.png";
  const verifyUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/verified.png";
  const { currentAudioUrl, setCurrentAudioUrl } = useContext(AudioContext);
  const { currentPod, setCurrentPod } = useContext(PostContext);
  const { audio_url, content, excerpt, img_url, pod_title } = currentPod;

  useEffect(() => {
    setCurrentAudioUrl(audio_url);
  }, []);
  return (
    <div style={podStyle}>
      <div style={containerStyle}>
        <div style={profileImageContainerStyle}>
          <img
            src={img_url ? img_url : imgUrl}
            style={profileImageStyle}
            alt="Profile"
          />
        </div>

        <div style={contentStyle}>
          <div style={displayNameContainerStyle}>
            <p style={fullNameStyle}>NPR</p>
            <img src={verifyUrl} style={verifiedStyle} alt="Verified badge" />
            <p style={displayNameStyle}>@NPR</p>
          </div>

          <p style={summaryStyle}>Amazing podcast, very insightful!</p>
          {/* 
          <img
            src={img_url ? img_url : imgUrl}
            style={featuredImageStyle}
            alt="Featured"
          /> */}

          <div style={snippetStyle}>
            <img
              src={imgUrl}
              alt="Featured response"
              style={featuredImageStyle}
            />
            <p style={transcriptStyle}>
              That's very fascinating what you said about that thing you were
              talking about, I was <span style={wordStyle}>particularly</span>{" "}
              surprised about how you mentioned that other thing does that one
              thing.
            </p>
          </div>

          <div style={buttonContainerStyle}>
            <div style={actionContainerStyle}>
              <ChartBarIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>1.1k</p>
            </div>

            <div style={actionContainerStyle}>
              <ArrowPathIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>24.4k</p>
            </div>

            <div style={actionContainerStyle}>
              <ChatBubbleLeftIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>123</p>
            </div>

            <div style={actionContainerStyle}>
              <HeartIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>5.23M</p>
            </div>
            <div style={actionContainerStyle}>
              <Audio
                height="50"
                width="50"
                radius="9"
                color={"#FF0078"}
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodResponseCard;

const podStyle = {
  paddingTop: "10px",
  borderBottom: "1px solid #2f2f2f",
  marginTop: "0px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  height: "auto",
  gap: "10px",
};

const containerStyle = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "20px",
  paddingRight: "10px",
  paddingLeft: "10px",
  width: "100%",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "100%",
  maxWidth: "546px",
};

const iconStyle = {
  height: "18px",
  width: "18px",
};

const actionTextStyle = {
  color: "#777",
};

const actionContainerStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "end",
  gap: "5px",
};

const profileImageContainerStyle = {
  marginRight: "10px",
};

const verifiedStyle = {
  width: "17px",
};

const profileImageStyle = {
  width: "48px",
  height: "48px",
  borderRadius: "500px",
};

const displayNameContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "5px",
};

const displayNameStyle = {
  color: "#999",
  fontSize: "15px",
};

const fullNameStyle = {
  fontWeight: "600",
  fontSize: "15px",
  color: "#FFF",
};

const summaryStyle = {
  fontSize: "13px",
  color: "#FFF",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
};

const featuredImageStyle = {
  width: "485px",
  height: "285px",
  borderRadius: "5px",
  objectFit: "cover",
  marginTop: "20px",
  marginBottom: "10px",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "start",
  gap: "50px",
  marginTop: "20px",
};

const dividerStyle = {
  width: "100%",
  height: "1px",
  background: "#444444",
  display: "flex",
  alignSelf: "center",
};

const smallerDividerStyle = {
  width: "96%",
  height: "1px",
  background: "#444444",
  display: "flex",
  alignSelf: "center",
};

const snippetStyle = {};

const transcriptStyle = {
  color: "#FFF",
  fontWeight: "500",
  wordSpacing: "4px",
  lineHeight: "25px",
};

const wordStyle = {
  color: "gold",
  fontWeight: "600",
};
