import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../utils/context/UserContext";
import { AuthContext } from "../../utils/context/AuthContext";
import Button from "../global-components/Button";
import UploadPopup from "../upload/UploadPopup";
import SidebarButton from "./SidebarButton";

const LeftSidebar = () => {
  const [showUpload, setShowUpload] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);

  const handlePostClick = () => {
    setShowUpload(true);
  };
  return (
    <>
      <div style={containerStyle}>
        <Link to={"/"}>
          <SidebarButton icon="homeIcon" text="Home" />
        </Link>
        <Link to={"/explore"}>
          <SidebarButton icon="viewIcon" text="Explore" />
        </Link>

        {isLoggedIn && (
          <>
            <Link to={"/notifications"}>
              <SidebarButton icon="chatIcon" text="Notifications" />
            </Link>
            <Link to={"/messages"}>
              <SidebarButton icon="envelopeIcon" text="Messages" />
            </Link>
            <Link to={"/profile"}>
              <SidebarButton icon="userIcon" text="Profile" />
            </Link>
            <SidebarButton icon="ellipsisIcon" text="More" />
            <Button
              text={"Post"}
              color={"#FFF"}
              background={"#FF0078"}
              handleClick={handlePostClick}
            />
          </>
        )}
      </div>

      {showUpload ? (
        <div style={popupContainerStyle}>
          <UploadPopup setShowUpload={setShowUpload} />
          <div
            style={popupBackgroundStyle}
            onClick={() => setShowUpload(false)}
          ></div>
        </div>
      ) : null}
    </>
  );
};

export default LeftSidebar;

const containerStyle = {
  padding: "20px 20px 20px 0",
  display: "flex",
  flexDirection: "column",
  width: "25%",
  borderRight: "1px solid #2f2f2f",
  gap: "30px",
};

const popupContainerStyle = {
  position: "absolute",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "10",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
};

const popupBackgroundStyle = {
  position: "absolute",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "-1",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
  background: "#242d3499",
};
