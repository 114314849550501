import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import Microphone from "./Microphone";
import AudioPlayer from "./AudioPlayer";

import axios from "axios";

const TranscribeRecording = ({}) => {
  const [files, setFiles] = useState([]);
  const [bucketUris, setBucketUris] = useState([]);

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  const submitFile = async (file) => {
    console.log(file);
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        "http://localhost:3000/file-upload",
        formData,
        config
      );
      const bucketUri = response.data.Location;
      setBucketUris([...bucketUris, bucketUri]);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const pushFile = (file) => {
    console.log(file);
    const _file = new File([file.blob], new Date().valueOf(), {
      type: file.blob.type,
    });
    submitFile(_file);
    setFiles([...files, file]);
  };

  return (
    <>
      <Microphone pushFile={pushFile} />
      <Grid container direction="column" spacing={3}>
        {files.map((file, index) => (
          <Grid key={index} item>
            <AudioPlayer file={file} bucketUri={bucketUris[index]} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TranscribeRecording;
