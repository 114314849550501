import {
  ArrowDownOnSquareIcon,
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PauseIcon,
  PlayIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";

const ControlButton = ({ icon, background, color, handleClick }) => {
  const controlButtonStyle = {
    height: "50px",
    width: "50px",
    borderRadius: "6px",
    background: background ? background : "#3b3b3b",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const playStyle = {
    height: "38px",
    width: "38px",
    color: color ? color : "#cbccca",
  };

  const icons = {
    playIcon: <PlayIcon style={playStyle} />,
    arrowSquareIcon: <ArrowPathRoundedSquareIcon style={playStyle} />,
    downArrowIcon: <ArrowDownOnSquareIcon style={playStyle} />,
    arrowPathIcon: <ArrowPathIcon style={playStyle} />,
    pauseIcon: <PauseIcon style={playStyle} />,
    muteIcon: <SpeakerXMarkIcon style={playStyle} />,
    downIcon: <ChevronDownIcon style={playStyle} />,
    upIcon: <ChevronUpIcon style={playStyle} />,
  };

  return (
    <div style={controlButtonStyle} onClick={handleClick}>
      {icons[icon]}
    </div>
  );
};

export default ControlButton;
