import React, { useContext } from "react";
import { PostContext } from "../../utils/context/ApiContext";
import Button from "../global-components/Button";

const RelevantSidebar = () => {
  const { currentPod, setCurrentPod } = useContext(PostContext);
  const { audio_url, content, excerpt, img_url, pod_title } = currentPod;
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2022/11/newhoustonwehaveapodcast.png";
  const verifyUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/verified.png";

  return (
    <div style={containerStyle}>
      <div style={relevantStyle}>
        <h1 style={headingStyle}>Relevant people</h1>

        <div style={infoContainerStyle}>
          <div style={profileImageContainerStyle}>
            <img
              src={img_url ? img_url : imgUrl}
              style={profileImageStyle}
              alt="Profile"
            />
          </div>

          <div style={contentStyle}>
            <div style={displayNameContainerStyle}>
              <div style={nameWrapper}>
                <div style={nameContainerStyle}>
                  <p style={fullNameStyle}>NPR</p>
                  <img
                    src={verifyUrl}
                    style={verifiedStyle}
                    alt="Verified badge"
                  />
                </div>
                <p style={displayNameStyle}>@NPR</p>
              </div>
              <button style={buttonStyle}>Follow</button>
            </div>

            <p style={summaryStyle}>{excerpt}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelevantSidebar;

const containerStyle = {
  width: "25%",
  height: "fit-content",
  paddingTop: "20px",
  paddingLeft: "20px",
  display: "flex",
  flexDirection: "row",
};

const nameContainerStyle = {
  display: "flex",
};

const nameWrapper = {};

const relevantStyle = {
  border: "1px solid #2f2f2f",
  borderRadius: "10px",
  padding: "10px",
  width: "100%",
};

const contentStyle = {
  width: "100%",
};

const infoContainerStyle = {
  display: "flex",
  flexDirection: "row",
};

const headingStyle = {
  color: "#FFF",
  fontSize: "20px",
  marginBottom: "10px",
};

const buttonStyle = {
  color: "#000",
  background: "#FFF",
  borderRadius: "500px",
  padding: "5px 10px",
  textTransform: "uppercase",
  fontWeight: "600",
  fontSize: "11px",
  alignSelf: "flex-start",
  justifySelf: "flex-end",
  flexBasis: "0",
  border: "none",
  cursor: "pointer",
};

const profileImageContainerStyle = {
  marginRight: "10px",
};

const verifiedStyle = {
  width: "17px",
  objectFit: "contain",
};

const profileImageStyle = {
  width: "48px",
  height: "48px",
  borderRadius: "500px",
};

const displayNameContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "100%",
};

const displayNameStyle = {
  color: "#999",
  fontSize: "15px",
};

const fullNameStyle = {
  fontWeight: "600",
  fontSize: "15px",
  color: "#FFF",
  marginRight: "3px",
};

const summaryStyle = {
  fontSize: "13px",
  color: "#FFF",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
  height: "50px",
  overflow: "hidden",
};
