import {
  ArrowPathIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon,
  HeartIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PostContext } from "../../utils/context/ApiContext";
import { AudioContext } from "../../utils/context/AudioContext";
import useOnScreen from "../../utils/hooks/useOnScreen";

const PodComponent = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  // const { audio_url, content, excerpt = "", img_url, pod_title } = pod;
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2022/11/newhoustonwehaveapodcast.png";
  const verifyUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/verified.png";
  const { currentAudioUrl, setCurrentAudioUrl } = useContext(AudioContext);
  const { curentPod, setCurrentPod } = useContext(PostContext);

  useEffect(() => {
    // setCurrentAudioUrl(audio_url);
  }, [isVisible]);

  const handlePodClick = () => {
    // setCurrentPod(pod);
    navigate(`/single/123456`);
  };

  return (
    <div ref={ref} style={podStyle} onClick={handlePodClick}>
      <div style={containerStyle}>
        <div style={profileImageContainerStyle}>
          <img src={imgUrl} style={profileImageStyle} alt="Profile" />
        </div>

        <div style={contentStyle}>
          <div style={displayNameContainerStyle}>
            <p style={fullNameStyle}>NPR</p>
            <img src={verifyUrl} style={verifiedStyle} alt="Verified badge" />
            <p style={displayNameStyle}>@NPR</p>
          </div>

          <p style={summaryStyle}>This is how we do it.</p>

          <img src={imgUrl} style={featuredImageStyle} alt="Featured" />

          <div style={buttonContainerStyle}>
            <div style={actionContainerStyle}>
              <ChartBarIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>308K</p>
            </div>

            <div style={actionContainerStyle}>
              <ArrowPathIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>582</p>
            </div>

            <div style={actionContainerStyle}>
              <ChatBubbleLeftIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>412</p>
            </div>

            <div style={actionContainerStyle}>
              <HeartIcon color={"#777"} style={iconStyle} />
              <p style={actionTextStyle}>1,545</p>
            </div>

            <div style={actionContainerStyle}>
              <ArrowUpTrayIcon color={"#777"} style={iconStyle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodComponent;

const podStyle = {
  paddingTop: "20px",
  width: "546px",
  maxWidth: "546px",
  borderBottom: "1px solid #2f2f2f",
  paddingBottom: "30px",
  scrollSnapAlign: "start",
  cursor: "pointer",
};

const containerStyle = {
  display: "flex",
  flexDirection: "row",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "100%",
  maxWidth: "546px",
};

const iconStyle = {
  height: "18px",
  width: "18px",
};

const actionTextStyle = {
  color: "#777",
  fontSize: "14px",
};

const actionContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  gap: "10px",
  marginTop: "5px",
};

const profileImageContainerStyle = {
  marginRight: "10px",
};

const verifiedStyle = {
  width: "17px",
};

const profileImageStyle = {
  width: "48px",
  height: "48px",
  borderRadius: "500px",
};

const displayNameContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "5px",
};

const displayNameStyle = {
  color: "#999",
  fontSize: "15px",
};

const fullNameStyle = {
  fontWeight: "600",
  fontSize: "15px",
  color: "#FFF",
};

const summaryStyle = {
  fontSize: "13px",
  color: "#FFF",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
  height: "125px",
  overflow: "hidden",
};

const featuredImageStyle = {
  width: "485px",
  height: "485px",
  borderRadius: "10px",
  objectFit: "cover",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "start",
  gap: "40px",
};

const audioStyle = {
  width: "100%",
};
