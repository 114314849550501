import React from "react";
import ExploreCard from "../explore/ExploreCard";

const WhatsHappening = () => {
  return (
    <div style={containerStyle}>
      <p style={headingStyle}>What's happening</p>

      <div>
        <ExploreCard title="Fox News" genre="Politics" />
        <ExploreCard title="NASA" genre="Space" />
        <ExploreCard title="Elon Musk" genre="Public Figure" />
        <ExploreCard title="Tokyo Drift" genre="Movie" />
        <ExploreCard title="M&Ms" genre="Food" />
        <ExploreCard title="Apple" genre="Technology" />
      </div>
    </div>
  );
};

export default WhatsHappening;

const containerStyle = {
  background: "#222",
  borderRadius: "10px",
  padding: "10px",
};

const headingStyle = {
  fontWeight: "600",
  color: "#FFF",
};
