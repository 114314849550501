import React, {
  useEffect,
  useContext,
  useRef,
  createRef,
  useState,
  useAnimatedRef,
} from "react";
import { PostContext } from "../../utils/context/ApiContext";
import PodCard from "./PodCard";
import "./Main.css";
import createScrollSnap from "scroll-snap";
import { ScreenContext } from "../../utils/context/ScreenContext";
import { ScrollContext } from "../../utils/context/ScrollContext";

const Main = () => {
  const { posts, setPosts, searchedPosts } = useContext(PostContext);
  const { isAuto, moveUp, moveDown } = useContext(ScrollContext);
  const { isMobile } = useContext(ScreenContext);
  const delay = 20000;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const podsContainer = document.querySelector(".pods-container");
    let timer = setTimeout(() => {
      let x = podsContainer.scrollTop;
      podsContainer.scrollTop = x + 370;
      console.log("ended");
      setIndex((index) => index + 1);
    }, delay);

    if (!isAuto) {
      console.log("CLEAR");
      clearTimeout(timer);
    }
  }, [index, isAuto]);

  useEffect(() => {
    const podsContainer = document.querySelector(".pods-container");
    let x = podsContainer.scrollTop;
    podsContainer.scrollTop = x - 370;
  }, [moveUp]);

  useEffect(() => {
    const podsContainer = document.querySelector(".pods-container");
    let x = podsContainer.scrollTop;
    podsContainer.scrollTop = x + 370;
  }, [moveDown]);

  const containerStyle = {
    marginTop: "0px",
    paddingTop: "20px",
    width: isMobile ? "100%" : "600px",
    maxWidth: isMobile ? "100%" : "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    borderRight: "1px solid #2f2f2f",
    height: "100vh",
    gap: "30px",
    overflowY: "scroll",
    scrollSnapType: "y mandatory",
    position: "relative",
    scrollBehavior: "smooth",
  };

  const podsContainerStyle = {
    height: "100%",
  };

  return (
    <div className="pods-container" style={containerStyle}>
      <div style={podsContainerStyle}>
        {posts.map((post) => (
          <PodCard key={post.id} pod={post} />
        ))}
      </div>
    </div>
  );
};

export default Main;
