import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import React from "react";

const MessageCard = () => {
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/2M7cCBz_400x400.jpg";

  return (
    <div style={containerStyle}>
      <img style={avatarStyle} src={imgUrl} alt="User avatar" />

      <div style={headingContainerStyle}>
        <div style={displayNameContainerStyle}>
          <p style={displayNameStyle}>702 Pros</p>
          <p>@702pros</p>
          <p>· Dec 23, 2019</p>

          <div style={spacerStyle}></div>

          <EllipsisHorizontalIcon style={iconStyle} />
        </div>

        <div>
          <p>Hello, how are you?</p>
        </div>
      </div>
    </div>
  );
};

export default MessageCard;

const containerStyle = {
  display: "flex",
  gap: "5px",
  padding: "15px 10px",
  width: "100%",
  color: "#777",
};

const displayNameStyle = {
  color: "#FFF",
  fontWeight: "600",
};

const headingContainerStyle = {
  width: "100%",
};

const displayNameContainerStyle = {
  display: "flex",
  gap: "5px",
  alignItems: "center",
};

const avatarStyle = {
  height: "48px",
  width: "48px",
  borderRadius: "500px",
  marginRight: "5px",
};

const iconStyle = {
  height: "28px",
  width: "28px",
};

const spacerStyle = {
  flex: "1",
};
