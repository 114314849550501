import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/context/UserContext";
import Main from "../main/Main";
import PodComponent from "../pod-component/PodComponent";
import EditProfile from "./EditProfile";
import {
  MapPinIcon,
  LinkIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";

const GET_USER = gql`
  query NewQuery($id: ID!) {
    user(id: $id, idType: DATABASE_ID) {
      avatar {
        url
      }
      databaseId
      firstName
      lastName
      username
      id
      name
      nickname
      userOptions {
        userBio
        userLocation
        userSite
        headerBanner {
          sourceUrl
        }
      }
    }
  }
`;

const Profile = () => {
  const { user, setUser, userId } = useContext(UserContext);
  const [searchId, setSearchId] = useState(1);
  const [focused, setFocused] = useState("pods");
  const [userProfile, setUserProfile] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/2M7cCBz_400x400.jpg";
  const bgImgUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/1500x500.jpg";

  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: userId },
  });

  useEffect(() => {
    setUserProfile(data);
    console.log(data);
    console.log(error);
  }, [loading, error, data]);

  const handleEditProfile = () => {
    setEditProfile((editProfile) => !editProfile);
  };

  const headerContainerStyle = {
    color: "#FFF",
    background: `url(${bgImgUrl})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "200px",
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
  };

  function removeHTTP(url) {
    return url?.replace(/^https?:\/\//, "");
  }

  return (
    <>
      <div style={containerStyle}>
        <div style={headerContainerStyle}></div>

        <div style={mainContainerStyle}>
          <div style={buttonContainerStyle}>
            <img style={profileImageStyle} src={imgUrl} alt="Profile" />
            <button style={editButtonStyle} onClick={handleEditProfile}>
              Edit Profile
            </button>
          </div>
          <div style={displayNameContainerStyle}>
            <p style={displayNameStyle}>{userProfile?.user?.name}</p>
            <p style={handleNameStyle}>@702pros</p>
          </div>
          <p style={bioStyle}>{userProfile?.user?.userOptions?.userBio}</p>

          <div style={detailsContainerStyle}>
            <div style={locationStyle}>
              <div style={iconContainerStyle}>
                <MapPinIcon style={iconStyle} />
                <p style={textStyle}>
                  {userProfile?.user?.userOptions?.userLocation}
                </p>
              </div>

              <div style={iconContainerStyle}>
                <LinkIcon style={iconStyle} />
                <a
                  style={linkTextStyle}
                  href={userProfile?.user?.userOptions?.userSite}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {removeHTTP(userProfile?.user?.userOptions?.userSite)}
                </a>
              </div>

              <div style={iconContainerStyle}>
                <CalendarDaysIcon style={iconStyle} />
                <p style={textStyle}>Joined January 2023</p>
              </div>
            </div>
          </div>

          <div style={countContainerStyle}>
            <p style={textStyle}>
              <span style={spanStyle}>27</span> Following
            </p>
            <p style={textStyle}>
              <span style={spanStyle}>1,115</span> Followers
            </p>
          </div>

          <div style={selectTabsStyle}>
            <button
              onClick={() => setFocused("pods")}
              style={focused == "pods" ? focusedTabStyle : tabsStyle}
            >
              Pods
            </button>
            <button
              onClick={() => setFocused("responses")}
              style={focused == "responses" ? focusedTabStyle : tabsStyle}
            >
              Responses
            </button>
            <button
              onClick={() => setFocused("repods")}
              style={focused == "repods" ? focusedTabStyle : tabsStyle}
            >
              Repods
            </button>
            <button
              onClick={() => setFocused("likes")}
              style={focused == "likes" ? focusedTabStyle : tabsStyle}
            >
              Likes
            </button>
          </div>

          <div style={feedContainerStyle}>
            <PodComponent />
            <PodComponent />
            <PodComponent />
            <PodComponent />
            <PodComponent />
            <PodComponent />
            <PodComponent />
            <PodComponent />
            <PodComponent />
            <PodComponent />
          </div>
        </div>
      </div>

      {editProfile ? (
        <div style={popupContainerStyle}>
          <EditProfile
            setEditProfile={setEditProfile}
            user={userProfile}
            setUser={setUser}
          />
          <div
            style={popupBackgroundStyle}
            onClick={() => setEditProfile(false)}
          ></div>
        </div>
      ) : null}
    </>
  );
};

export default Profile;

const containerStyle = {
  height: "100%",
  width: "100%",
};

const profileImageStyle = {
  height: "150px",
  width: "150px",
  borderRadius: "500px",
  marginTop: "-75px",
  border: "5px solid #000",
  marginLeft: "20px",
  cursor: "pointer",
};

const mainContainerStyle = {
  height: "100%",
  width: "100%",
};

const buttonContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const selectTabsStyle = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  paddingTop: "20px",
  borderBottom: "1px solid #2f2f2f",
  background: "#000",
};

const tabsStyle = {
  background: "#000",
  color: "#777",
  border: "none",
  fontSize: "16px",
  paddingBottom: "20px",
  fontWeight: "500",
  borderBottom: "2px solid #000",
  paddingLeft: "10px",
  paddingRight: "10px",
  cursor: "pointer",
};

const focusedTabStyle = {
  ...tabsStyle,
  color: "#FFF",
  fontWeight: "600",
  borderBottom: "2px solid #FF0078",
};

const editButtonStyle = {
  border: "1px solid #777",
  background: "#000",
  color: "#FFF",
  padding: "10px 30px",
  borderRadius: "500px",
  fontWeight: "600",
  marginRight: "20px",
  cursor: "pointer",
};

const displayNameStyle = {
  fontWeight: "600",
  color: "#FFF",
  fontSize: "20px",
};

const handleNameStyle = {
  fontWeight: "400",
  color: "#999",
  fontSize: "16px",
};

const displayNameContainerStyle = {
  padding: "20px",
};

const bioStyle = {
  color: "#FFF",
  padding: "5px 20px",
  fontSize: "15px",
};

const detailsContainerStyle = {
  padding: "10px 20px",
};

const locationStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  color: "#999",
  fontWeight: "500",
  fontSize: "16px",
};

const countContainerStyle = {
  display: "flex",
  gap: "10px",
  padding: "10px 20px",
};

const textStyle = {
  fontWeight: "500",
  fontSize: "14px",
  color: "#777",
};

const linkTextStyle = {
  ...textStyle,
  color: "#FF0078",
};

const spanStyle = {
  fontWeight: "600",
  fontSize: "14px",
  color: "#FFF",
};

const feedContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const popupContainerStyle = {
  position: "absolute",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "10",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
};

const popupBackgroundStyle = {
  position: "absolute",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "-1",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
  background: "#242d3499",
};

const iconStyle = {
  height: "18px",
  width: "18px",
};

const iconContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "3px",
};
