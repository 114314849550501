import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../utils/context/UserContext";
import Main from "../main/Main";
import PodCard from "../main/PodCard";
import UserCard from "../user-component/UserCard";
import ExploreCard from "./ExploreCard";
import { PostContext } from "../../utils/context/ApiContext";

const Explore = () => {
  const [focused, setFocused] = useState("foryou");
  const { allUsers } = useContext(UserContext);
  const { searchTerm } = useContext(PostContext);

  useEffect(() => {
    setFocused("top");
  }, [searchTerm]);

  return (
    <div style={containerStyle}>
      <div style={selectTabsStyle}>
        {searchTerm && (
          <button
            onClick={() => setFocused("top")}
            style={focused == "top" ? focusedTabStyle : tabsStyle}
          >
            Top
          </button>
        )}
        <button
          onClick={() => setFocused("foryou")}
          style={focused == "foryou" ? focusedTabStyle : tabsStyle}
        >
          For you
        </button>
        <button
          onClick={() => setFocused("trending")}
          style={focused == "trending" ? focusedTabStyle : tabsStyle}
        >
          Trending
        </button>
        <button
          onClick={() => setFocused("news")}
          style={focused == "news" ? focusedTabStyle : tabsStyle}
        >
          News
        </button>
        <button
          onClick={() => setFocused("politics")}
          style={focused == "politics" ? focusedTabStyle : tabsStyle}
        >
          Politics
        </button>
        <button
          onClick={() => setFocused("sports")}
          style={focused == "sports" ? focusedTabStyle : tabsStyle}
        >
          Sports
        </button>
      </div>

      {searchTerm ? null : (
        <div style={feedContainerStyle}>
          <ExploreCard title="Fox News" genre="Politics" />
          <ExploreCard title="NASA" genre="Space" />
          <ExploreCard title="Elon Musk" genre="Public Figure" />
          <ExploreCard title="Tokyo Drift" genre="Movie" />
          <ExploreCard title="M&Ms" genre="Food" />
          <ExploreCard title="Apple" genre="Technology" />
        </div>
      )}

      <div style={dividerStyle}></div>

      {searchTerm ? null : (
        <div style={feedContainerStyle}>
          <p style={headingStyle}>Who to follow</p>
          {allUsers.map((user) => (
            <UserCard key={user.id} user={user} explore />
          ))}
        </div>
      )}

      <div style={dividerStyle}></div>

      <div></div>
    </div>
  );
};

export default Explore;

const containerStyle = {};

const feedContainerStyle = {
  padding: "20px",
};

const selectTabsStyle = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  paddingTop: "20px",
  borderBottom: "1px solid #2f2f2f",
};

const headingStyle = {
  color: "#FFF",
  fontWeight: "600",
  fontSize: "18px",
};

const tabsStyle = {
  background: "#000",
  color: "#777",
  border: "none",
  fontSize: "16px",
  paddingBottom: "20px",
  fontWeight: "500",
  borderBottom: "2px solid #000",
  paddingLeft: "10px",
  paddingRight: "10px",
  cursor: "pointer",
};

const focusedTabStyle = {
  ...tabsStyle,
  color: "#FFF",
  fontWeight: "600",
  borderBottom: "2px solid #FF0078",
};

const dividerStyle = {
  height: "1px",
  width: "100%",
  background: "#2f2f2f",
};
