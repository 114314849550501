import React, { useContext } from "react";
import SearchPodComponent from "../pod-component/SearchPodComponent";
import { PostContext } from "../../utils/context/ApiContext";

const SearchComponent = () => {
  const { searchedPosts } = useContext(PostContext);

  const displayPods = searchedPosts?.map((each) => (
    <SearchPodComponent key={each.id} pod={each} />
  ));

  return <div style={containerStyle}>{displayPods}</div>;
};

export default SearchComponent;

const containerStyle = {};
