import React, { useContext, useEffect, useState } from "react";
import { AudioContext } from "../../utils/context/AudioContext";
import Player from "../../utils/hooks/useAudio";
import ControlButton from "./ControlButton";
import { ScrollContext } from "../../utils/context/ScrollContext";

const AudioControls = () => {
  const { currentAudioUrl, setCurrentAudioUrl } = useContext(AudioContext);
  const { isAuto, setIsAuto, setMoveUp, setMoveDown, moveUp, moveDown } =
    useContext(ScrollContext);
  const [audio] = useState(new Audio(currentAudioUrl));
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [upPressed, setUpPressed] = useState(false);
  const [downPressed, setDownPressed] = useState(false);

  useEffect(() => {
    audio.src = currentAudioUrl;

    if (!isPaused) {
      audio.play();
      setIsPlaying(true);
    }
  }, [currentAudioUrl]);

  const handlePlayClick = () => {
    audio.play();
    setIsPlaying(true);
    setIsPaused(false);
  };

  const handlePauseClick = () => {
    audio.pause();
    setIsPlaying(false);
    setIsPaused(true);
  };

  const handleMuteClick = () => {
    if (audio.muted) {
      audio.muted = false;
      setIsMuted(false);
    } else {
      audio.muted = true;
      setIsMuted(true);
    }
  };

  const handleRefreshClick = () => {
    setRefreshed(true);
    setIsMuted(false);
    setIsPaused(false);
    setIsPlaying(true);
    audio.pause();
    audio.currentTime = 0;
    audio.play();

    setTimeout(() => {
      setRefreshed(false);
    }, 100);
  };

  const handleAutoClick = () => {
    setIsAuto(true);
  };

  const handleManualClick = () => {
    setIsAuto(false);
  };

  const handleUpClick = () => {
    setMoveUp(!moveUp);

    setTimeout(() => {
      setRefreshed(false);
    }, 100);
  };

  const handleDownClick = () => {
    setMoveDown(!moveDown);

    setTimeout(() => {
      setRefreshed(false);
    }, 100);
  };

  return (
    <div style={containerStyle}>
      <ControlButton
        handleClick={handleAutoClick}
        icon={"arrowSquareIcon"}
        background={isAuto ? "#FFF" : null}
        color={isAuto ? "#000" : null}
      />
      <ControlButton
        handleClick={handleManualClick}
        icon={"downArrowIcon"}
        background={!isAuto ? "#FFF" : null}
        color={!isAuto ? "#000" : null}
      />
      <ControlButton
        icon={"playIcon"}
        handleClick={handlePlayClick}
        background={isPlaying ? "#2ef92e" : null}
        color={isPlaying ? "#000" : null}
      />
      <ControlButton
        icon={"pauseIcon"}
        handleClick={handlePauseClick}
        background={isPaused ? "#FF0078" : null}
        color={isPaused ? "#000" : null}
      />
      <ControlButton
        icon={"muteIcon"}
        handleClick={handleMuteClick}
        background={isMuted ? "orange" : null}
        color={isMuted ? "#000" : null}
      />
      <ControlButton
        icon={"arrowPathIcon"}
        handleClick={handleRefreshClick}
        background={refreshed ? "cyan" : null}
        color={refreshed ? "#000" : null}
      />
      <ControlButton icon={"upIcon"} handleClick={handleUpClick} />
      <ControlButton icon={"downIcon"} handleClick={handleDownClick} />
    </div>
  );
};

export default AudioControls;

const containerStyle = {
  padding: "10px",
  width: "70px",
  borderRight: "1px solid #2f2f2f",
  borderBottom: "1px solid #2f2f2f",
  color: "#FFF",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};
