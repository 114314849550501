import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
} from "react-router-dom";
import { PostContextProvider } from "./utils/context/ApiContext";
import { AudioContextProvider } from "./utils/context/AudioContext";
import { ScreenContextProvider } from "./utils/context/ScreenContext";
import { UserContextProvider } from "./utils/context/UserContext";
import { ScrollContextProvider } from "./utils/context/ScrollContext";
import { AuthContextProvider } from "./utils/context/AuthContext";
import { ApolloProvider } from "@apollo/client";
import client from "./utils/Apollo";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ScrollContextProvider>
          <UserContextProvider>
            <PostContextProvider>
              <AudioContextProvider>
                <ScreenContextProvider>
                  <AuthContextProvider>
                    <App />
                  </AuthContextProvider>
                </ScreenContextProvider>
              </AudioContextProvider>
            </PostContextProvider>
          </UserContextProvider>
        </ScrollContextProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);
