import React, { useContext } from "react";
import "./CategoriesList.css";
import { PostContext } from "../../utils/context/ApiContext";
import { useNavigate } from "react-router-dom";

const CategoriesList = () => {
  const navigate = useNavigate();
  const categories = [
    "Sports",
    "News",
    "Politics",
    "Food",
    "Technology",
    "Space",
    "Movie",
    "Crypto",
  ];
  const { searchPosts, setSearchTerm } = useContext(PostContext);

  const handleCategoryClick = (term) => {
    searchPosts(term);
    setSearchTerm(term);
    navigate(`/explore/${term}`);
  };

  const displayCategories = categories.map((each) => {
    return (
      <div
        key={each}
        style={listContainerStyle}
        onClick={() => handleCategoryClick(each)}
      >
        <p style={listStyle}>{each}</p>
      </div>
    );
  });

  return (
    <div className="categories-container" style={containerStyle}>
      {displayCategories}
    </div>
  );
};

export default CategoriesList;

const containerStyle = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(4, 150px)",
  gridAutoFlow: "column",
  gridAutoColumns: "150px",
  gap: "50px",
  alignItems: "center",
  height: "100%",
  overflowX: "scroll",
  paddingTop: "5px",
};

const listContainerStyle = {
  background: "#222",
  padding: "7px 15px",
  borderRadius: "500px",
  cursor: "pointer",
  textAlign: "center",
};

const listStyle = {
  color: "#FFF",
  fontWeight: "500",
};
