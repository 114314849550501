import { gql, useQuery } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";

export const PostContext = createContext();

const GET_ALL_PODS = gql`
  {
    posts(first: 20) {
      nodes {
        title
        excerpt
        databaseId
        author {
          node {
            name
            username
            nickname
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        postsFields {
          audioFile
          parentId
          podResponses
          podRepods
          podLikes
          podInteractions
        }
      }
    }
  }
`;

export const PostContextProvider = ({ children }) => {
  const { setIsLoading } = useContext(UserContext);
  const [posts, setPosts] = useState([]);
  const [searchedPosts, setSearchedPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPod, setCurrentPod] = useState({});
  const postsUrl = "https://www.tappods.com/wp-json/tpapi/v1/getpods";
  const { loading, error, data } = useQuery(GET_ALL_PODS);

  useEffect(() => {
    // async function fetchPosts() {
    //   let response = await fetch(postsUrl);
    //   response = await response.json();

    //   if (!response.error) {
    //     setPosts(response);
    //   }
    // }

    // fetchPosts();
    if (data?.posts.nodes.length > 0 ? setPosts(data?.posts.nodes) : null)
      setPosts(data?.posts.nodes);

    console.log("LOADING", loading);
    console.log("ERROR", error);
    console.log("DATA", data?.posts.nodes);
  }, [loading, error, data]);

  const searchPosts = async (term) => {
    setIsLoading(true);
    let response = await fetch(
      `https://www.tappods.com/wp-json/tpapi/v1/search?search_term=${term}&pod_count=20`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    response = await response.json();

    if (!response.error) {
      setSearchedPosts(response);
      setPosts(response);
    } else {
      alert(response.error);
    }
    setIsLoading(false);
  };

  const likePod = async (pod_id, user_id) => {
    let response = await fetch(
      "https://www.tappods.com/wp-json/tpapi/v1/add_like",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pod_id: pod_id,
          user_id: user_id,
        }),
      }
    );

    response = await response.json();
    if (!response.error) {
      console.log(response);
      alert("LIKED");
    } else {
      alert(response.error);
    }
  };

  return (
    <PostContext.Provider
      value={{
        posts,
        setPosts,
        currentPod,
        setCurrentPod,
        searchPosts,
        searchedPosts,
        setSearchedPosts,
        likePod,
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
