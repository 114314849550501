import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import "./UploadPopup.css";
import TranscribeRecording from "./TranscribeRecording";
import SpeechComponent from "./SpeechComponent";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const UploadPopup = ({ setShowUpload }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcribeText, setTranscribeText] = useState();
  const recordIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/record-icon.svg";
  const uploadIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/upload-icon.svg";
  const imageIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/gallery.png";
  const closedCaptionIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/subtitles.png";
  const locationIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/placeholder.png";
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "600px",
    gap: "20px",
    height: "auto",
    overflow: "hidden",
    transition: "ease 300ms",
    background: "#000",
    padding: "25px",
    borderRadius: "10px",
    position: "relative",
    paddingTop: "40px",
  };

  const handleCloseClick = () => {
    setShowUpload(false);
  };

  const handleRecordingClick = () => {
    setIsRecording((isRecording) => !isRecording);
  };

  const handlePostClick = (e) => {
    e.preventDefault();
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div style={containerStyle}>
      <XMarkIcon
        style={xmarkIconStyle}
        color={"#FFF"}
        onClick={handleCloseClick}
      />
      <div style={buttonContainerStyle}>
        <button
          style={isRecording ? recordingButtonStyle : buttonStyle}
          onClick={handleRecordingClick}
        >
          <img
            className={isRecording ? "pulse" : null}
            style={iconStyle}
            src={recordIconUrl}
            alt="Record icon"
          />{" "}
          Record
        </button>
        <button style={buttonStyle}>
          <img style={iconStyle} src={uploadIconUrl} alt="Upload icon" />
          Upload
        </button>
      </div>

      <TranscribeRecording />
      {/* <SpeechComponent /> */}

      <div style={textareaContainerStyle}>
        <textarea style={textareaStyle} value={transcribeText}>
          What's new?
        </textarea>
      </div>

      <div style={dividerStyle}></div>

      <div style={actionsContainerStyle}>
        <img style={actionIconStyle} src={imageIconUrl} alt="Icon" />
        <img
          style={actionIconStyle}
          src={closedCaptionIconUrl}
          alt="Closed caption icon"
        />
        <img
          style={actionIconStyle}
          src={locationIconUrl}
          alt="Location icon"
        />

        <button style={postButtonstyle} onClick={handlePostClick}>
          Post
        </button>
      </div>
    </div>
  );
};

export default UploadPopup;

const xmarkIconStyle = {
  width: "24px",
  height: "24px",
  objectFit: "contain",
  position: "absolute",
  right: "10px",
  top: "10px",
  cursor: "pointer",
};

const buttonContainerStyle = {
  display: "flex",
  gap: "15px",
};

const buttonStyle = {
  width: "125px",
  padding: "8px 10px",
  border: "1px solid #FFF",
  borderRadius: "500px",
  background: "#000",
  color: "#FFF",
  fontSize: "17px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  cursor: "pointer",
};

const postButtonstyle = {
  padding: "8px 30px",
  borderRadius: "500px",
  color: "#FFF",
  background: "#FF0078",
  border: "none",
  fontSize: "17px",
};

const iconStyle = {
  height: "18px",
  width: "18px",
  filter: "invert(1)",
};

const recordingButtonStyle = {
  width: "125px",
  padding: "8px 10px",
  border: "1px solid red",
  borderRadius: "500px",
  background: "#000",
  color: "#FFF",
  fontSize: "17px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  cursor: "pointer",
};

const actionIconStyle = {
  height: "26px",
  width: "26px",
  filter: "invert(1)",
};

const textareaContainerStyle = {
  width: "100%",
  padding: "10px",
};

const textareaStyle = {
  width: "100%",
  background: "#000",
  color: "#FFF",
  border: "none",
  height: "100px",
  fontSize: "15px",
  resize: "none",
};

const dividerStyle = {
  width: "100%",
  height: "1px",
  background: "#444444",
  display: "flex",
  alignSelf: "center",
};

const actionsContainerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "10px",
};
