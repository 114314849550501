import React from "react";

const SiteLinksComponent = ({ siteLinks }) => {
  const displaySiteLinks = siteLinks.map((each) => {
    return (
      <div key={each.title} style={linksContainerStyle}>
        <p style={textStyle}>{each.text}</p>
        <a href={each.href} style={linkStyle}>
          {each.title}
        </a>
      </div>
    );
  });

  return <div style={containerStyle}>{displaySiteLinks}</div>;
};

export default SiteLinksComponent;

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
};

const linksContainerStyle = {
  display: "flex",
  gap: "5px",
};

const textStyle = {
  fontSize: "14px",
};

const linkStyle = {
  color: "#FFF",
  fontWeight: "600",
};
