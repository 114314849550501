import { gql, useMutation, useQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

const GET_ALL_USERS = gql`
  {
    users(first: 10) {
      nodes {
        name
        avatar {
          url
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $email: String = ""
    $firstName: String = ""
    $id: ID = ""
  ) {
    updateUser(input: { email: $email, firstName: $firstName })
  }
`;

export const UserContextProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [userId, setUserId] = useState(1);
  const createUserUrl = "https://www.tappods.com/wp-json/tpapi/v1/tpcreateuser";
  const loginUserUrl = "https://www.tappods.com/wp-json/tpapi/v1/authuser";
  const usersUrl = "https://www.tappods.com/wp-json/tpapi/v1/users";
  const { loading, error, data } = useQuery(GET_ALL_USERS);

  useEffect(() => {
    if (data?.users.nodes.length > 0) {
      setAllUsers(data?.users.nodes);
    }

    console.log("USERS", data?.users.nodes);
  }, [loading, error, data]);

  const loginUser = async (credentials) => {
    setIsLoading(true);
    let response = await fetch(loginUserUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: credentials.email,
        password: credentials.password,
        key: process.env.REACT_APP_API_TOKEN,
      }),
    });
    response = await response.json();

    if (response.id) {
      setUser(response);
      localStorage.setItem("user", JSON.stringify(response));
      console.log("USER", response);
      setUserId(response.id);
      setIsLoggedIn(true);
    } else {
      alert("Unable to login, please check your email/password and try again.");
    }
    setIsLoading(false);
  };

  const createUser = async (credentials) => {
    setIsLoading(true);
    let response = await fetch(createUserUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: credentials.email,
        password: credentials.password,
        email: credentials.email,
        fname: credentials.fname,
        key: process.env.REACT_APP_API_TOKEN,
      }),
    });
    response = await response.json();

    if (!response.error) {
      setUser(response);
      localStorage.setItem("user", JSON.stringify(response));
    } else {
      alert(response.error);
    }
    setIsLoading(false);
  };

  const fetchUsers = async () => {
    let response = await fetch(usersUrl);
    response = await response.json();

    if (!response.error) {
      console.log(response);
    } else {
      alert(response);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loginUser,
        createUser,
        isLoading,
        setIsLoading,
        showLogin,
        setShowLogin,
        allUsers,
        setAllUsers,
        fetchUsers,
        isLoggedIn,
        setIsLoggedIn,
        userId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
