import React, { useState } from 'react';
import UploadComponent from '../upload/UploadComponent';

const CreatePodResponse = ({ img_url }) => {
    const [showRecording, setShowRecording] = useState(false);

    const logoUrl =
        'https://www.tappods.com/wp-content/uploads/2023/01/tp-icon.svg';
    const imgUrl =
        'https://www.tappods.com/wp-content/uploads/2022/11/newhoustonwehaveapodcast.png';

    const handleShowRecordingClick = () => {
        setShowRecording((showRecording) => !showRecording);
    };

    const recordButtonContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: showRecording ? '20px' : '0',
        transition: 'ease 300ms',
    };

    const imageContainerStyle = {
        height: '38px',
        width: '38px',
        background: showRecording ? 'cyan' : 'red',
        borderRadius: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    };

    return (
        <div style={containerStyle}>
            <div style={recordButtonContainerStyle}>
                <img
                    src={img_url ? img_url : imgUrl}
                    style={profileImageStyle}
                    alt='Profile'
                />

                <div style={recordStyle}>
                    <p style={textStyle}>Record a reply</p>
                    <div
                        style={imageContainerStyle}
                        onClick={handleShowRecordingClick}>
                        <img
                            style={imageStyle}
                            src={logoUrl}
                            alt='Tappods logo'
                        />
                    </div>
                </div>
            </div>

            <div style={recordingContainerStyle}>
                <UploadComponent showRecording={showRecording} />
            </div>
        </div>
    );
};

export default CreatePodResponse;

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px',
};

const recordingContainerStyle = {
    width: '100%',
    color: '#FFF',
};

const textStyle = {
    marginRight: '10px',
    color: '#FFF',
    fontSize: '18px',
    fontWeight: '500',
};

const imageStyle = {
    height: '25px',
    width: '25px',
    marginRight: '3px',
    objectFit: 'contain',
};

const profileImageStyle = {
    width: '48px',
    height: '48px',
    borderRadius: '500px',
};

const recordStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
