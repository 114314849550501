import React, { useEffect, useState, useRef } from "react";
import { XMarkIcon, CameraIcon } from "@heroicons/react/24/outline";
import { Navigation } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const EditProfile = ({ setEditProfile, user, setUser }) => {
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const [background, setBackground] = useState();
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/2M7cCBz_400x400.jpg";
  const bgImgUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/1500x500.jpg";
  const updateUserUrl = "https://www.tappods.com/wp-json/tpapi/v1/update_user";

  const [userData, setUserData] = useState({
    id: user.user.databaseId,
    name: user.user.name,
    bio: user.user.userOptions.userBio,
    location: user.user.userOptions.userLocation,
    site: user.user.userOptions.userSite,
  });

  useEffect(() => {
    console.log("USER", user);
  }, []);

  const backgroundContainerStyle = {
    color: "#FFF",
    backgroundImage: `url(${background ? background : bgImgUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    opacity: ".9",
  };

  const handleSaveButtonClick = (e) => {
    e.preventDefault();

    updateUser(userData);
    setEditProfile(false);
  };

  const updateUser = async (credentials) => {
    let response = await fetch(updateUserUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: credentials.id,
        name: credentials.name,
        bio: credentials.bio,
        location: credentials.location,
        site: credentials.site,
        key: process.env.REACT_APP_API_TOKEN,
      }),
    });
    response = await response.json();

    if (!response.error) {
      console.log(response);

      navigate("/profile");
    } else {
      alert(response.error);
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setUserData({ ...userData, [name]: value });
  };

  const handleCloseButtonClick = () => {
    setEditProfile(false);
  };

  const handleBannerChangeClick = () => {
    inputFile.current.click();
  };

  const handleBannerDeleteClick = () => {};

  const handleBannerChange = (e) => {
    let files = e.target.files;

    setBackground(URL.createObjectURL(files[0]));
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={topContainerStyle}>
          <XMarkIcon
            color={"#FFF"}
            style={closeIconStyle}
            onClick={handleCloseButtonClick}
          />
          <p style={editTitleStyle}>Edit profile</p>
          <button style={saveButtonStyle} onClick={handleSaveButtonClick}>
            Save
          </button>
        </div>

        <div style={backgroundContainerStyle}>
          <div style={buttonContainerStyle}>
            <div style={editButtonContainerStyle}>
              <CameraIcon
                color={"#FFF"}
                style={iconStyle}
                onClick={handleBannerChangeClick}
              />
            </div>

            <div style={editButtonContainerStyle}>
              <XMarkIcon
                color={"#FFF"}
                style={iconStyle}
                onClick={handleBannerDeleteClick}
              />
            </div>
          </div>

          <div style={profileImageContainerStyle}>
            <div style={editProfileContainerStyle}>
              <img style={profileImageStyle} src={imgUrl} alt="Profile" />
              <div style={editProfileImageButtonContainerStyle}>
                <CameraIcon color={"#FFF"} style={iconStyle} />
              </div>
            </div>
          </div>
        </div>

        <div style={inputWrapperStyle}>
          <div style={inputContainerStyle}>
            <p style={inputHeadingStyle}>Name</p>
            <input
              style={inputStyle}
              name="name"
              onChange={handleChange}
              value={userData.name}
            />
          </div>

          <div style={inputContainerStyle}>
            <p style={inputHeadingStyle}>Bio</p>
            <textarea
              style={inputStyle}
              name="bio"
              onChange={handleChange}
              value={userData.bio}
            />
          </div>

          <div style={inputContainerStyle}>
            <p style={inputHeadingStyle}>Location</p>
            <input
              style={inputStyle}
              name="location"
              onChange={handleChange}
              value={userData.location}
            />
          </div>

          <div style={inputContainerStyle}>
            <p style={inputHeadingStyle}>Website</p>
            <input
              style={inputStyle}
              name="site"
              onChange={handleChange}
              value={userData.site}
            />
          </div>

          <div style={birthdateContainerStyle}>
            <p>Birth date · </p>
            <button style={editButtonStyle}>Edit</button>
          </div>
        </div>
      </div>

      <input
        id="img_upload"
        type="file"
        name="img"
        ref={inputFile}
        style={imgInputStyle}
        onChange={handleBannerChange}
      />
    </>
  );
};

export default EditProfile;

const containerStyle = {
  borderRadius: "10px",
  width: "600px",
  background: "#000",
  height: "700px",
  overflowY: "scroll",
};

const topContainerStyle = {
  height: "50px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  position: "sticky",
  top: "0",
  background: "#00000099",
  zIndex: "10",
};

const editTitleStyle = {
  flex: 1,
  color: "#FFF",
  fontWeight: "600",
  fontSize: "18px",
  marginLeft: "40px",
};

const saveButtonStyle = {
  background: "#FFF",
  color: "#000",
  borderRadius: "500px",
  border: "none",
  padding: "8px 16px",
  fontWeight: "600",
  cursor: "pointer",
};

const buttonContainerStyle = {
  position: "absolute",
  margin: "0 auto",
  display: "flex",
  gap: "20px",
};

const editButtonContainerStyle = {
  width: "48px",
  height: "48px",
  borderRadius: "500px",
  background: "#00000099",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const editProfileImageButtonContainerStyle = {
  width: "48px",
  height: "48px",
  borderRadius: "500px",
  background: "#00000099",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateY(-50%) translateX(-50%)",
};

const editProfileContainerStyle = {
  position: "relative",
};

const closeIconStyle = {
  width: "22px",
  height: "22px",
  cursor: "pointer",
};

const iconStyle = {
  width: "24px",
  height: "24px",
  cursor: "pointer",
};

const profileImageContainerStyle = {
  position: "absolute",
  left: "20px",
  bottom: "-75px",
  width: "125px",
  height: "125px",
  borderRadius: "500px",
};

const profileImageStyle = {
  objectFit: "contain",
  width: "125px",
  height: "125px",
  borderRadius: "500px",
  border: "5px solid #000",
};

const inputContainerStyle = {
  background: "#000",
  border: "1px solid #444",
  color: "#FFF",
  borderRadius: "5px",
  padding: "10px",
  marginBottom: "20px",
};

const inputStyle = {
  background: "#000",
  border: "none",
  color: "#FFF",
  fontSize: "16px",
  width: "100%",
};

const inputWrapperStyle = {
  padding: "25px",
  marginTop: "70px",
};

const inputHeadingStyle = {
  color: "#777",
  fontSize: "14px",
};

const birthdateContainerStyle = {
  display: "flex",
  color: "#777",
  alignItems: "center",
};

const editButtonStyle = {
  background: "#000",
  color: "#FF0078",
  fontSize: "15px",
  cursor: "pointer",
};

const imgInputStyle = {
  display: "none",
};
