import {
  Bars3Icon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/solid";
import { gapi } from "gapi-script";
import React, { useContext, useEffect, useState } from "react";
import { Audio } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { PostContext } from "../../utils/context/ApiContext";
import { ScreenContext } from "../../utils/context/ScreenContext";
import { UserContext } from "../../utils/context/UserContext";
import Button from "../global-components/Button";
import LoginSignup from "../login-signup/LoginSignup";
import TestUpload from "../upload/TestUpload";
import { AuthContext } from "../../utils/context/AuthContext";
import UploadPopup from "../upload/UploadPopup";
import UserCard from "../user-component/UserCard";
import SearchComponent from "./SearchComponent";

const Header = () => {
  const navigate = useNavigate();
  const [showUpload, setShowUpload] = useState(false);
  const [mode, setMode] = useState("login");
  const [focusInput, setFocusInput] = useState(false);
  const [isOverProfile, setIsOverProfile] = useState(false);
  const {
    searchPosts,
    searchedPosts,
    setSearchedPosts,
    setSearchTerm,
    searchTerm,
  } = useContext(PostContext);
  const {
    user,
    setUser,
    loginUser,
    isLoading,
    setIsLoading,
    showLogin,
    setShowLogin,
  } = useContext(UserContext);
  const { isLoggedIn, setIsLoggedIn, signOut } = useContext(AuthContext);
  const { isMobile } = useContext(ScreenContext);
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2022/12/tp-logo.svg";
  const profileImgUrl =
    "https://www.tappods.com/wp-content/uploads/2022/11/newhoustonwehaveapodcast.png";

  const handleSearchInput = (e) => {
    e.preventDefault();

    setSearchTerm(e.target.value);

    setTimeout(() => {
      if (e.target.value !== "") {
        searchPosts(e.target.value);
        console.log(searchedPosts);
      }
    }, 2000);
  };

  const handleUploadClick = () => {
    setShowUpload(true);
  };

  const handleLoginClick = () => {
    setShowLogin(true);
    setMode("login");
  };

  const handleSignupClick = () => {
    setShowLogin(true);
    setMode("signup");
  };

  const handleFocusInput = () => {
    setFocusInput(true);
  };

  const handleChatClick = () => {
    navigate("/notifications");
  };

  const handleProfileClick = () => {
    setIsOverProfile(!isOverProfile);
  };

  const handleProfileEnter = () => {
    // setIsOverProfile(!isOverProfile);
  };

  const handleLogoutClick = () => {
    setUser({});
    setIsLoggedIn(false);
    localStorage.removeItem("user");
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 0px",
    width: isMobile ? "100%" : "1200px",
    maxWidth: isMobile ? "100%" : "1200px",
  };

  return (
    <>
      {isLoading && (
        <div style={loadingContainerStyle}>
          <Audio
            height="100"
            width="100"
            radius="9"
            color={"#FF0078"}
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}

      <div style={containerStyle}>
        {isMobile ? <Bars3Icon style={barsStyle} color={"#FFF"} /> : null}

        <img
          src={imgUrl}
          style={logoStyle}
          alt="Logo"
          onClick={() => navigate("/")}
        />

        <div style={inputContainer}>
          {isMobile ? (
            <MagnifyingGlassIcon
              className="pointer"
              color={"#FFF"}
              style={magnifyingStyle}
            />
          ) : (
            <>
              <input
                style={inputStyle}
                placeholder="Search TapPods"
                value={searchTerm}
                onChange={handleSearchInput}
                onFocus={handleFocusInput}
              />

              <MagnifyingGlassIcon
                className="pointer"
                color={"#939393"}
                style={magnifyingStyle}
              />
            </>
          )}

          {focusInput ? (
            <>
              <div style={searchingPopupContainerStyle}>
                <SearchComponent />
              </div>
              <div
                style={focusBackgroundStyle}
                onClick={() => setFocusInput(false)}
              ></div>
            </>
          ) : null}
        </div>

        {isMobile ? null : (
          <div style={buttonContainerStyle}>
            {!isLoggedIn && (
              <Button
                text={"Sign Up"}
                color={"#FFF"}
                background={"#000"}
                padding={"12px 20px 12px 20px"}
                handleClick={handleSignupClick}
              />
            )}

            {!isLoggedIn && (
              <>
                <Button
                  text={"Log In"}
                  color={"#000"}
                  background={"#FFF"}
                  handleClick={handleLoginClick}
                />
              </>
            )}
            {isLoggedIn && (
              <>
                <Button
                  text={"Upload"}
                  color={"#FFF"}
                  background={"#242424"}
                  padding={"12px 20px 12px 20px"}
                  handleClick={handleUploadClick}
                >
                  <PlusIcon color={"#FFF"} style={plusStyle} />
                </Button>

                <ChatBubbleBottomCenterTextIcon
                  className="pointer"
                  color={"#cccccc"}
                  style={chatButtonStyle}
                  onClick={handleChatClick}
                />

                <img
                  src={profileImgUrl}
                  style={profileImageStyle}
                  alt="Profile"
                  onClick={handleProfileClick}
                  onMouseEnter={handleProfileEnter}
                  onMouseLeave={handleProfileEnter}
                />
                {isOverProfile && (
                  <div style={popupProfileStyle}>
                    <button
                      style={logoutButtonStyle}
                      onClick={handleLogoutClick}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {showLogin ? (
        <div style={popupContainerStyle}>
          <LoginSignup loginSignup={mode} setShowLogin={setShowLogin} />
          <div
            style={popupBackgroundStyle}
            onClick={() => setShowLogin(false)}
          ></div>
        </div>
      ) : null}

      {showUpload ? (
        <div style={popupContainerStyle}>
          {/* <UploadPopup setShowUpload={setShowUpload} /> */}
          <TestUpload />
          <div
            style={popupBackgroundStyle}
            onClick={() => setShowUpload(false)}
          ></div>
        </div>
      ) : null}
    </>
  );
};

export default Header;

const loadingContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "#00000099",
  zIndex: 100,
};

const logoStyle = {
  height: "30px",
  filter: "invert(1)",
  cursor: "pointer",
};

const inputContainer = {
  display: "flex",
  background: "#2f2f2f",
  borderRadius: "500px",
  width: "fit-content",
  height: "fit-content",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "0",
  position: "relative",
};

const inputStyle = {
  border: "none",
  background: "#2f2f2f",
  padding: "12px",
  borderRadius: "500px",
  width: "475px",
  color: "#FFF",
  cursor: "pointer",
  textTransform: "capitalize",
  zIndex: "10",
};

const searchingPopupContainerStyle = {
  position: "absolute",
  top: "36px",
  background: "#000",
  borderRadius: "5px",
  height: "600px",
  width: "450px",
  color: "#FFF",
  padding: "10px",
  border: "1px solid #444",
  margin: "0 auto",
  marginLeft: "5px",
  zIndex: "5",
  boxShadow: "0 0 20px #FFFFFF33",
  overflowX: "hidden",
  overflowY: "scroll",
};

const magnifyingStyle = {
  height: "22px",
  width: "22px",
  position: "absolute",
  right: "15px",
  pointerEvents: "none",
  zIndex: "20",
};

const plusStyle = {
  height: "20px",
  width: "20px",
  marginRight: "10px",
};

const chatButtonStyle = {
  height: "30px",
  width: "30px",
  cursor: "pointer",
};

const buttonContainerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  position: "relative",
};

const popupContainerStyle = {
  position: "absolute",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "10",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
};

const popupBackgroundStyle = {
  position: "absolute",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "-1",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
  background: "#242d3499",
};

const focusBackgroundStyle = {
  position: "absolute",
  width: "100vw",
  height: "100vh",
  zIndex: "1",
  top: "0",
  left: "-600px",
  bottom: "0",
  right: "0",
};

const profileImageStyle = {
  width: "48px",
  height: "48px",
  borderRadius: "500px",
  cursor: "pointer",
};

const barsStyle = {
  height: "40px",
  width: "40px",
  cursor: "pointer",
};

const popupProfileStyle = {
  background: "#2f2f2f",
  borderRadius: "10px",
  padding: "10px 20px",
  position: "absolute",
  top: "50px",
  right: "-15px",
};

const logoutButtonStyle = {
  background: "transparent",
  fontWeight: "500",
  border: "none",
  color: "#FFF",
  cursor: "pointer",
};
