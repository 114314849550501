import React, { useState, useContext } from "react";
import { ScreenContext } from "../utils/context/ScreenContext";
import { PostContext } from "../utils/context/ApiContext";
import { useLocation } from "react-router-dom";
import AudioControls from "../components/audio-controls/AudioControls";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import Main from "../components/main/Main";
import Profile from "../components/profile/Profile";
import RelevantSidebar from "../components/relevant-sidebar/RelevantSidebar";
import RightSidebar from "../components/right-sidebar/RightSidebar";
import SinglePod from "./SinglePod";
import Notifications from "../components/notifications/Notifications";
import Explore from "../components/explore/Explore";
import Messages from "../components/messages/Messages";
import MessageView from "../components/messages/MessageView";
import ResultsHeader from "../components/categories-list/ResultsHeader";

const Home = () => {
  const [scroll, setScroll] = useState("auto");
  const location = useLocation();
  const { isMobile } = useContext(ScreenContext);
  const { searchedPosts, searchTerm } = useContext(PostContext);

  const centerColumnStyle = {
    width: location.pathname.includes("/messages") ? "400px" : "600px",
    minWidth: location.pathname.includes("/messages") ? "400px" : "600px",
    maxWidth: "600px",
    height: "100%w",
    borderRight: "1px solid #2f2f2f",
    borderBottom: "1px solid #2f2f2f",
  };

  return (
    <div style={containerStyle}>
      {isMobile ? null : <LeftSidebar />}

      <div style={centerColumnStyle}>
        {location.pathname.includes("/explore") && searchedPosts.length > 0 && (
          <ResultsHeader />
        )}

        {location.pathname == "/" && (
          <Main scroll={scroll} setScroll={setScroll} />
        )}

        {location.pathname.includes("/single") && <SinglePod />}
        {location.pathname.includes("/profile") && <Profile />}
        {location.pathname.includes("/notifications") && <Notifications />}
        {location.pathname.includes("/explore") && <Explore />}
        {location.pathname.includes("/messages") && <Messages />}
      </div>

      {isMobile ? null : location.pathname.includes("/messages") ? null : (
        <AudioControls scroll={scroll} setScroll={setScroll} />
      )}

      {location.pathname.includes("/single") ? (
        <RelevantSidebar />
      ) : location.pathname.includes("/messages") ? (
        <MessageView />
      ) : (
        <RightSidebar />
      )}
    </div>
  );
};

export default Home;

const containerStyle = {
  display: "flex",
  flexDirection: "row",
  height: "100%",
};
