import { createContext, useState, useEffect } from "react";

export const ScreenContext = createContext();

export const ScreenContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const mediaQuery = window.matchMedia("(max-width: 768px)");

  useEffect(() => {
    setIsMobile(mediaQuery.matches);
  }, []);

  return (
    <ScreenContext.Provider
      value={{
        isMobile,
        setIsMobile,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};
