import React, { useState, useContext } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../utils/context/UserContext";
import { AuthContext } from "../../utils/context/AuthContext";

const LoginSignup = ({ loginSignup, setShowLogin }) => {
  const [mode, setMode] = useState(loginSignup);
  const { signUpWithEmail, signIn, signInWithGoogle } = useContext(AuthContext);
  const [credentials, setCredentials] = useState({
    fname: "",
    lname: "",
    username: "",
    email: "",
    password: "",
  });
  const logoUrl =
    "https://www.tappods.com/wp-content/uploads/2022/12/tp-logo.svg";
  const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const handleCloseClick = () => {
    setShowLogin(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (mode == "signup") {
      signUpWithEmail(credentials);
    } else {
      signIn(credentials);
    }

    handleCloseClick();

    setCredentials({
      fname: "",
      lname: "",
      email: "",
      password: "",
      key: process.env.API_TOKEN,
    });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setCredentials({ ...credentials, [name]: value });
  };

  const handleGoogleClick = (e) => {
    e.preventDefault();

    signInWithGoogle();
  };

  const onSuccess = (res) => {
    console.log("success:", res);
  };
  const onFailure = (err) => {
    console.log("failed:", err);
  };

  return (
    <div style={containerStyle}>
      <XMarkIcon
        style={xmarkIconStyle}
        color={"#FFF"}
        onClick={handleCloseClick}
      />
      <img style={logoStyle} src={logoUrl} alt="TapPods logo" />
      <p style={headingTextStyle}>
        {mode == "login"
          ? "Login to TapPods to continue."
          : "Surf Micro Pods. Free on TapPods."}
      </p>

      <button onClick={handleGoogleClick}>Sign In with Google</button>

      <div style={dividerContainerStyle}>
        <div style={dividerStyle}></div>
        <p style={textStyle}>or</p>
        <div style={dividerStyle}></div>
      </div>

      <div style={formContainerStyle}>
        <form style={formStyle} onSubmit={handleFormSubmit}>
          {mode == "signup" ? (
            <>
              <p style={headingTextStyle}>Sign Up with your Email Address</p>
              <input
                style={inputStyle}
                onChange={handleChange}
                value={credentials.fname}
                type="text"
                name="fname"
                placeholder="Name"
                required
              />
            </>
          ) : null}

          <input
            style={inputStyle}
            name="email"
            type="text"
            placeholder="Email"
            required
            onChange={handleChange}
            value={credentials.email}
          />
          <input
            style={inputStyle}
            name="password"
            type="password"
            placeholder="Password"
            required
            onChange={handleChange}
            value={credentials.password}
          />

          <button style={loginButtonStyle} type="submit">
            {mode == "login" ? "Login" : "Sign Up"}
          </button>
        </form>
      </div>

      <div style={dividerStyle}></div>
      {mode == "login" ? (
        <div style={disclaimerContainerStyle}>
          <p>Don't have an account?</p>
          <button style={signupButtonStyle} onClick={() => setMode("signup")}>
            Sign Up
          </button>
        </div>
      ) : (
        <div style={disclaimerContainerStyle}>
          <p>Have an account?</p>
          <button style={signupButtonStyle} onClick={() => setMode("login")}>
            Log In
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginSignup;

const containerStyle = {
  width: "600px",
  height: "750px",
  background: "#000",
  color: "#FFF",
  borderRadius: "15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "20px",
  padding: "25px 50px",
  position: "relative",
};

const xmarkIconStyle = {
  width: "24px",
  height: "24px",
  objectFit: "contain",
  position: "absolute",
  right: "10px",
  top: "10px",
  cursor: "pointer",
};

const logoStyle = {
  maxWidth: "200px",
  filter: "invert(1)",
  marginBottom: "15px",
};

const formContainerStyle = {
  width: "100%",
};

const inputStyle = {
  height: "50px",
  padding: "10px",
  borderRadius: "5px",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "15px",
  justifyContent: "center",
};

const headingTextStyle = {
  fontWeight: "500",
  fontSize: "16px",
  color: "#FFF",
  textAlign: "center",
};

const textStyle = {
  color: "#7a7a7a",
};

const googleButtonStyle = {
  width: "100%",
  background: "#FFF",
  color: "#000",
  height: "50px",
  borderRadius: "5px",
  border: "none",
};

const loginButtonStyle = {
  background: "#FF0078",
  borderRadius: "500px",
  padding: "12px 20px",
  color: "#FFF",
  textTransform: "uppercase",
  border: "none",
  marginTop: "5px",
  width: "125px",
  alignSelf: "center",
  fontWeight: "500",
  cursor: "pointer",
};

const signupButtonStyle = {
  padding: "12px",
  background: "#000",
  color: "#FF0078",
  fontWeight: "500",
  cursor: "pointer",
  fontSize: "14px",
};

const dividerContainerStyle = {
  display: "flex",
  gap: "20px",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "10px",
  marginBottom: "10px",
};

const dividerStyle = {
  height: "1px",
  width: "100%",
  background: "#7a7a7a",
};

const disclaimerContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};
