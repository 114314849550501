import {
  ChatBubbleBottomCenterTextIcon,
  EllipsisHorizontalCircleIcon,
  HomeIcon,
  UserCircleIcon,
  ViewfinderCircleIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import React from "react";

const SidebarButton = ({ icon, text }) => {
  const itemStyle = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    cursor: "pointer",
  };

  const iconStyle = {
    height: "30px",
    width: "30px",
  };

  const textStyle = {
    color: "#FFF",
  };

  const icons = {
    homeIcon: <HomeIcon color={"#FFF"} style={iconStyle} />,
    viewIcon: <ViewfinderCircleIcon color={"#FFF"} style={iconStyle} />,
    chatIcon: (
      <ChatBubbleBottomCenterTextIcon color={"#FFF"} style={iconStyle} />
    ),
    userIcon: <UserCircleIcon color={"#FFF"} style={iconStyle} />,
    ellipsisIcon: (
      <EllipsisHorizontalCircleIcon color={"#FFF"} style={iconStyle} />
    ),
    envelopeIcon: <EnvelopeIcon color={"#FFF"} style={iconStyle} />,
  };

  return (
    <div style={itemStyle}>
      {icons[icon]}
      <p style={textStyle}>{text}</p>
    </div>
  );
};

export default SidebarButton;
