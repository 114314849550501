import LinksColumnComponent from "./LinksColumnComponent";
import SiteLinksComponent from "./SiteLinksComponent";
import { siteLinks } from "./data/LinksData";
import { exploreLinks } from "./data/LinksData";
import "./Links.css";

const Footer = () => {
  const imgUrl =
    "https://www.tappods.com/wp-content/uploads/2022/12/tp-logo.svg";

  return (
    <div style={containerStyle}>
      <div style={logoContainerStyle}>
        <img src={imgUrl} alt="logo" style={logoStyle} />
      </div>

      <div style={linksContainerStyle}>
        <LinksColumnComponent
          title={"Explore & Inspire"}
          links={exploreLinks}
        />
        <LinksColumnComponent title={"About"} links={exploreLinks} />
        <LinksColumnComponent title={"Partner With Us"} links={exploreLinks} />

        <div>
          <p style={headingStyle}>
            Meet the 702 Pros Family—Balancing Home and Business{" "}
          </p>

          <SiteLinksComponent siteLinks={siteLinks} />
        </div>
      </div>
    </div>
  );
};

export default Footer;

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "space-between",
  height: "auto",
  color: "#FFF",
  padding: "50px 25px 50px 25px",
  flexDirection: "column",
};

const logoContainerStyle = {
  width: "100%",
  marginBottom: "20px",
};

const headingStyle = {
  fontWeight: "600",
  marginBottom: "15px",
};

const logoStyle = {
  height: "35px",
  display: "inline-block",
  filter: "invert(1)",
};

const linksWrapperStyle = {
  display: "flex",
  flexDirection: "column",
};

const linksContainerStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 500px",
};
