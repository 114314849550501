import React from "react";

const UploadComponent = ({ showRecording }) => {
  const recordIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/record-icon.svg";
  const uploadIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/upload-icon.svg";
  const imageIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/gallery.png";
  const closedCaptionIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/subtitles.png";
  const locationIconUrl =
    "https://www.tappods.com/wp-content/uploads/2023/01/placeholder.png";

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: showRecording ? "20px 0" : "0",
    gap: "20px",
    height: showRecording ? "280px" : "0",
    overflow: "hidden",
    transition: "ease 300ms",
  };

  return (
    <div style={containerStyle}>
      <div style={dividerStyle}></div>
      <div style={buttonContainerStyle}>
        <button style={buttonStyle}>
          <img style={iconStyle} src={recordIconUrl} alt="Record icon" /> Record
        </button>
        <button style={buttonStyle}>
          <img style={iconStyle} src={uploadIconUrl} alt="Upload icon" />
          Upload
        </button>
      </div>

      <div style={textareaContainerStyle}>
        <textarea style={textareaStyle}>What's new?</textarea>
      </div>

      <div style={dividerStyle}></div>

      <div style={actionsContainerStyle}>
        <img style={actionIconStyle} src={imageIconUrl} alt="Icon" />
        <img
          style={actionIconStyle}
          src={closedCaptionIconUrl}
          alt="Closed caption icon"
        />
        <img
          style={actionIconStyle}
          src={locationIconUrl}
          alt="Location icon"
        />

        <button style={postButtonstyle}>Post</button>
      </div>
    </div>
  );
};

export default UploadComponent;

const buttonContainerStyle = {
  display: "flex",
  gap: "15px",
};

const buttonStyle = {
  width: "125px",
  padding: "8px 10px",
  border: "1px solid #FFF",
  borderRadius: "500px",
  background: "#000",
  color: "#FFF",
  fontSize: "17px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

const postButtonstyle = {
  padding: "8px 30px",
  borderRadius: "500px",
  color: "#FFF",
  background: "#FF0078",
  border: "none",
  fontSize: "17px",
  textTransform: "uppercase",
  fontWeight: "500",
};

const iconStyle = {
  height: "18px",
  width: "18px",
  filter: "invert(1)",
};

const actionIconStyle = {
  height: "26px",
  width: "26px",
  filter: "invert(1)",
};

const textareaContainerStyle = {
  width: "100%",
  padding: "10px",
};

const textareaStyle = {
  width: "100%",
  background: "#000",
  color: "#FFF",
  border: "none",
  height: "100px",
  fontSize: "15px",
  resize: "none",
};

const dividerStyle = {
  width: "100%",
  height: "1px",
  background: "#444444",
  display: "flex",
  alignSelf: "center",
};

const actionsContainerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "10px",
};
