import React, { useContext } from 'react';
import { PostContext } from '../../utils/context/ApiContext';
import Button from '../global-components/Button';

const UserCard = ({ explore, user }) => {
  const { avatar, name } = user;
  const imgUrl =
    'https://www.tappods.com/wp-content/uploads/2022/11/newhoustonwehaveapodcast.png';
  const verifyUrl =
    'https://www.tappods.com/wp-content/uploads/2023/01/verified.png';

  return (
    <div style={containerStyle}>
      <div style={relevantStyle}>
        <div style={infoContainerStyle}>
          <div style={profileImageContainerStyle}>
            <img
              src={avatar.url ? avatar.url : imgUrl}
              style={profileImageStyle}
              alt='Profile'
            />
          </div>

          <div style={contentStyle}>
            <div style={displayNameContainerStyle}>
              <div style={nameWrapper}>
                <div style={nameContainerStyle}>
                  <p style={fullNameStyle}>{name}</p>
                  <img
                    src={verifyUrl}
                    style={verifiedStyle}
                    alt='Verified badge'
                  />
                </div>
                <p style={displayNameStyle}>@{name}</p>
              </div>
              <button style={buttonStyle}>Follow</button>
            </div>

            {explore ? (
              <p style={summaryStyle}>
                Follow us for interesting insights on the happenings of the
                world and relavant information.
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;

const containerStyle = {
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'row',
  margin: '20px 0',
};

const nameContainerStyle = {
  display: 'flex',
};

const nameWrapper = {};

const relevantStyle = {
  borderRadius: '10px',
  //   padding: "10px",
  width: '100%',
};

const contentStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const infoContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const headingStyle = {
  color: '#FFF',
  fontSize: '20px',
  marginBottom: '10px',
};

const buttonStyle = {
  color: '#000',
  background: '#FFF',
  borderRadius: '500px',
  padding: '5px 10px',
  textTransform: 'uppercase',
  fontWeight: '600',
  fontSize: '11px',
  alignSelf: 'flex-start',
  justifySelf: 'flex-end',
  flexBasis: '0',
  border: 'none',
  cursor: 'pointer',
};

const profileImageContainerStyle = {
  marginRight: '10px',
};

const verifiedStyle = {
  width: '17px',
  objectFit: 'contain',
};

const profileImageStyle = {
  width: '48px',
  height: '48px',
  borderRadius: '500px',
};

const displayNameContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: '10px',
};

const displayNameStyle = {
  color: '#999',
  fontSize: '15px',
};

const fullNameStyle = {
  fontWeight: '600',
  fontSize: '15px',
  color: '#FFF',
  marginRight: '3px',
};

const summaryStyle = {
  fontSize: '16px',
  color: '#FFF',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
  height: '50px',
  overflow: 'hidden',
};
